function calculateDiscountAmount(basePrice:any, discountPercentage:any = 0) {

    if (discountPercentage <= 0) {
        return 0;
    }
    return (parseFloat(basePrice) * parseFloat(discountPercentage)) / 100;
}

export default function getPrecioNeto(item:any, factor:any = 1, applyDiscountMarca:boolean = false, applyDiscountItem:boolean = false, applyDiscountIndexed:boolean = false, is_molecula:boolean = false, debug:boolean = false) {

    if (factor == undefined) {
        factor = 1;
    }
    let precio_neto:any;

    if (is_molecula) {
        precio_neto = parseFloat(item.mejor_precio_molecula);
    }else{
        if(item?.neto){
            precio_neto = parseFloat(item.neto ?? 0);
        }else{
            precio_neto = parseFloat(item.precio_neto ?? 0);
        }
    }


    if(applyDiscountMarca){
        const discountAmount = calculateDiscountAmount(precio_neto, item.discountMarca).toFixed(2);
        precio_neto -= parseFloat(discountAmount);
    }

    if(applyDiscountItem){
        const discountAmountItem = calculateDiscountAmount(precio_neto, item.discountItem).toFixed(2);
        precio_neto -= parseFloat(discountAmountItem);
    }

    if(applyDiscountIndexed){
        if(item.desc_indexados){
            const arrayDiscounts = JSON.parse(item.desc_indexados) as Array<any>;

            arrayDiscounts.map((ele:any) => {
                const discountAmountIndexed = calculateDiscountAmount(precio_neto, ele.amountWithoutPercent).toFixed(2);
                precio_neto -= parseFloat(discountAmountIndexed);
            })
        }


    }

    const net:any = precio_neto / factor;

    return parseFloat(net).toFixed(2)
}