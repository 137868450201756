import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

// Definimos un tipo para el objeto de configuración de la solicitud
type RequestConfig = AxiosRequestConfig;

// La función acepta una URL, datos, configuración de solicitud y método
export default async function makeRequest<T>(
  url: string,
  data: any = null,
  config: RequestConfig = {},
  method: string = 'GET',
  retries: number = 3
): Promise<AxiosResponse<T>> {
  try {
    const response: AxiosResponse<T> = await axios.request({
      url,
      method,
      data,
      ...config,
    });

    // Si la solicitud es exitosa, devolvemos la respuesta
    return response;
  } catch (error) {
    // Si ocurre un error y quedan intentos de reintentos, lo intentamos de nuevo
    if (retries > 0) {
      console.log(`Retrying request, ${retries} attempts remaining...`);
      return makeRequest(url, data, config, method, retries - 1);
    }

    // Si no quedan intentos de reintentos o si el error es irreversible, lanzamos el error
    throw error;
  }
}
