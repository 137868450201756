//store/auth/actions.js

import Swal from 'sweetalert2';
import { Axios } from 'axios';
import { Commit, Dispatch } from 'vuex';

export default {
    logout({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, { axios, apiRoute, router }: { axios: Axios, apiRoute: string, router: any }) {
        // Make API call to logout the user
        const token = localStorage.getItem(`authToken`)

        axios.post(`${apiRoute}/auth/logout`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(() => {
            // Handle successful logout

            commit(`SET_AUTHENTICATION`, false);
            localStorage.removeItem('authToken');
            commit('resetUser');
            dispatch('orders/resetCartItems');
            router.go('/');
        })
            .catch((error) => {
                // Handle logout error
                console.log(error);
            });
    },

    async login({ commit }: { commit: Commit }, { input, axios, apiRoute }: { input: any, axios: Axios, apiRoute: string }) {
        try {
            const response = await axios.post(`${apiRoute}/auth/login`, {
                email: input.email,
                password: input.password
            });

            commit(`SET_AUTHENTICATION`, true);
            
            commit(`SET_USER`, JSON.stringify(response.data.user));
            localStorage.setItem('authToken', response.data.access_token);

            return "Autenticación completada.";
        } catch (error) {
            commit(`SET_AUTHENTICATION`, false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No se pudo iniciar sesión,\n Verifique las credenciales',
                showConfirmButton: false
            });
            throw new Error("Inicio de sesión fallido");
        }
    },

    async setConfig({ commit }: { commit: Commit }, { data, axios, apiRoute }: { data: any, axios: Axios, apiRoute: string }) {
        try {
            const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

            // Configure headers with the token
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            // Make an API request to store the pedido with the token in headers
            await axios.put(apiRoute + '/cliente/update', data, {
                headers: headers,
            });

            // Commit the addToCart mutation or take any other action based on the response
            commit("setConfigUser", data);

            await axios
                .post(`${apiRoute}/auth/me`, null, {
                    headers: headers,
                })
                .then((response) => {
                    commit(`SET_USER`, JSON.stringify(response.data));
                    return (response.status);
                });

        } catch(error) {
        // Handle the error here (e.g., show an error message)
        console.error("Error updating config:", error);
    }
}

};
