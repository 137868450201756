import Swal from "sweetalert2";
import store from "@/store";
import { Axios } from "axios";

async function getFiltros(axios:Axios, apiRoute:string) {
    try {
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const response = axios.get(`${apiRoute}/cat/filtros`, {
            headers
        })

        return response;
    } catch (err) {
        console.log(err);
    }
}

function setOptionsMarcaHtml(data:any) {
    let marcas = data.marcas;
    if (data?.checkboxStatesMarca?.length > 0) {
        marcas = data.checkboxStatesMarca;
    }

    let html = `
    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <input type="checkbox" class="mr-2" value="selectAllOptions" id="selectAllOptionsMarca" checked>
            <h2 class="my-2 text-lg font-semibold mb-2">Selecciona las marcas:</h2>
        </div>
        <div>
            <button title="Seleccionar todos" id="selectAllButtonMarca" class="text-blue-500 hover:text-blue-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-check inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Seleccionar Todos -->
            </button>
            <button title="Deseleccionar todos" id="deselectAllButtonMarca" class="text-red-500 hover:text-red-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-minus inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Deseleccionar Todos -->
            </button>
        </div>
    </div>

    <!-- Add a wrapper div around the input element -->
    <div class="my-2 flex items-center border border-gray-300 rounded">
        <!-- Add the BoxIcon search icon -->
        <i class='bx bx-search text-gray-400 ml-2'></i>
        <!-- Add some classes to the input element to style it -->
        <input
            type="text"
            id="filterMarcasInput"
            placeholder="Filtrar marcas..."
            class="w-full py-1 px-2 outline-none"
        >
    </div>


    <div class="max-h-40 overflow-auto">
        <div class="grid grid-cols-2 gap-2" id="marcasContainer">`;

    marcas.map((item:any) => {
        html += `
                <label class="flex items-center text-left">
                    <input type="checkbox" class="mr-2" value="${item?.marca ?? item?.name}" name="checkboxOptionMarca" ${item?.checked === true ? 'checked' : ''}>
                    ${item?.marca ?? item?.name}
                </label>
                `;
    });

    html += `
        </div>
    </div>`;

    return html;
}

function setHtml(data:any) {
    const html = `
    <div class="grid gap-4 grid-cols-1">

        <div>
            ${setOptionsMarcaHtml(data)}
        </div>

    </div>
`;

    return html;
}

export default function openFiltrosMinmaxModal(axios:Axios, apiRoute:string) {
    Swal.showLoading();

    // Access the getters
    const selectedFilters = store.getters['catalogConfig/selectedFilters'];

    getFiltros(axios, apiRoute).then((response:any) => {

        const data = {
            ...response.data,
            ...selectedFilters
        }

        const html = setHtml(data);
        Swal.fire({
            focusConfirm: false,
            title: 'Opciones de Filtrado',
            html,
            showCancelButton: true,
            confirmButtonText: 'Aplicar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-confirm-button',
                popup: 'swal2-popup swal2-modal swal2-show swal-wide'
            },
            preConfirm: () => {
                const checkOptionsMarcaElement = document.getElementById('selectAllOptionsMarca') as HTMLInputElement;
                const checkOptionsMarca = checkOptionsMarcaElement.checked;
                const optionCheckboxesMarca = document.querySelectorAll('input[type="checkbox"][name^="checkboxOptionMarca"]') as NodeList;
                const checkboxStatesMarca:any = [];

                optionCheckboxesMarca.forEach((checkbox:any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.value,
                        checked: checkbox.checked
                    });
                });

                return {
                    checkOptionsMarca,
                    checkboxStatesMarca,
                };
            },
            didOpen: () => {
                const filterInputMarcas = document.getElementById('filterMarcasInput') as HTMLInputElement;
                const marcasContainer = document.getElementById('marcasContainer') as HTMLElement;

                const selectAllButtonMarca = document.getElementById('selectAllButtonMarca') as HTMLElement;
                const deselectAllButtonMarca = document.getElementById('deselectAllButtonMarca') as HTMLElement;
                const optionCheckboxesMarca = document.querySelectorAll('input[type="checkbox"][name^="checkboxOptionMarca"]') as NodeList;
                const checkboxStatesMarca = [];

                optionCheckboxesMarca.forEach((checkbox:any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.name,
                        checked: checkbox.checked
                    });
                });

                selectAllButtonMarca.addEventListener('click', () => {
                    optionCheckboxesMarca.forEach((checkbox:any) => {
                        checkbox.checked = true;
                    });
                });

                deselectAllButtonMarca.addEventListener('click', () => {
                    optionCheckboxesMarca.forEach((checkbox:any) => {
                        checkbox.checked = false;
                    });
                });

                // Add an event listener to the filter input element to handle user input
                filterInputMarcas.addEventListener('input', (event:any) => {
                    // Get the current value of the filter input
                    const filterValue = event.target.value.toLowerCase();

                    // Get all label elements within the marcas container
                    const labels = marcasContainer.querySelectorAll('label');

                    // Loop over each label element
                    labels.forEach((label:any) => {
                        // Get the text content of the label
                        const labelText = label.textContent.toLowerCase();

                        // Check if the label text includes the filter value
                        if (labelText.includes(filterValue)) {
                            // If it does, show the label
                            label.style.display = 'flex';
                        } else {
                            // If it doesn't, hide the label
                            label.style.display = 'none';
                        }
                    });
                });

            },
        }).then((result:any) => {
            if (result.isConfirmed) {

                store.dispatch('catalogConfig/setSelectedFilters', {
                    checkOptionsMarca: result.value.checkOptionsMarca,
                    checkboxStatesMarca: result.value.checkboxStatesMarca,
                });

                store.dispatch('catalogConfig/getCatalogoConfig', {
                    axios,
                    apiRoute,
                    searchParams: {
                        filtro: null,
                        ...result.value
                    },
                })

            }
        });
    });


}