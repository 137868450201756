import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const selectedFilters = {
    checkOptionsMarca: true as boolean,
    checkboxStatesMarca: [] as any,
}

const state = {
    catalogoconfigdata: [] as any,
    loading: true as boolean,
    total: null as number|null,
    searchParams: {} as any,
    errorMessage: null as string|null,
    selectedFilters,
    next_page_url: null as string|null,
    last_page_url: null as string|null,
    prev_page_url: null as string|null,
    first_page_url: null as string|null,
    last_page: "" as string|null|number,
    current_page: "" as string|null|number,
    isLoadingMore: false as boolean,
    showing: null as number|null,
    from: 1 as number|null,
    to: 50 as number|null,
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};


// Definir el tipo del estado
export type CatalogConfigState = typeof state;

export type CatalogConfigSelectedFilters = typeof state.selectedFilters;
