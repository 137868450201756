import { Commit } from "vuex";
import { CatalogConfigSelectedFilters, CatalogConfigState } from ".";
import { Axios } from "axios";

export default {
    setSelectedFilters({ commit }: {commit:Commit}, selectedFilters:CatalogConfigSelectedFilters) {
        commit('SET_SELECTED_FILTERS', selectedFilters);
    },

    async getCatalogoConfig(
        { commit, state } : {commit:Commit, state:CatalogConfigState},
        { axios, apiRoute, url = null, searchParams = null } : {axios:Axios, apiRoute: string, url: string|null, searchParams: any}
    ) {
        const token = localStorage.getItem("authToken");
        commit("setLoading", true);

        if (url == null) {
            url = `${apiRoute}/cat/showConfig`;
        }
        try {
            const response = await axios.post(url, { ...searchParams }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });
            commit("setNextPageUrl", response.data.next_page_url);
            commit("setPrevPageUrl", response.data.prev_page_url);

            commit("setFirstPageUrl", response.data.first_page_url);
            commit("setLastPageUrl", response.data.last_page_url);
            commit("setLastPage", response.data.last_page);
            commit("setCurrentPage", response.data.current_page);
            commit("setTotal", response.data.total);
            commit("setShowing", response.data.to);
            commit("setFrom", response.data.from);
            commit("setCatalogoConfigData", state.catalogoconfigdata = response.data.data);

        } catch (err) {
            // Handle error
            commit(
                "setErrorMessage",
                "An error occurred while fetching data. Please try again."
            );

            console.error(err);
        } finally {
            commit("setLoading", false);
        }
    },

    async setMinMax(
        { commit, state } : {commit:Commit, state:CatalogConfigState},
        { axios, apiRoute, data, searchParams = null } : { axios: Axios, apiRoute:string, data:any, searchParams:any}
    ) {

        const token = localStorage.getItem("authToken");

        if (searchParams !== null || searchParams !== '') {
            data = {
                ...data,
                ...searchParams
            }
        }
        try {
            await axios.post(`${apiRoute}/cat/setMinMax`, { ...data }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            })

            // Crear una copia del array catalogoconfigdata con las propiedades actualizadas
            const updatedCatalogoConfigData = state.catalogoconfigdata.map((item:any) => {
                // Verificar si este item tiene min y max, y actualizarlos si es necesario
                if (item.min !== undefined) {
                    if (data.min) {
                        item.min = data.min ?? 0;
                    }
                }
                if (item.max !== undefined) {
                    if (data.max) {
                        item.max = data.max ?? 0;
                    }
                }
                return item;
            });
            // Actualizar el estado con el nuevo objeto
            commit("setCatalogoConfigData", updatedCatalogoConfigData);


        } catch (err) {
            // Handle error
            commit(
                "setErrorMessage",
                "An error occurred while fetching data. Please try again."
            );

            console.error(err);
        } finally {
            commit("setLoading", false);
        }
    },

    async setOneMinMax(
        { commit, state } : { commit: Commit, state: CatalogConfigState},
        { axios, apiRoute, data } : { axios: Axios, apiRoute: string, data: any}
    ) {
        const token = localStorage.getItem("authToken");

        try {
            const response = await axios.post(
                `${apiRoute}/cat/setOneMinMax`,
                { ...data },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Verificar si la respuesta fue exitosa antes de continuar
            if (response.status === 200) {
                // Buscar el elemento en el array catalogoconfigdata por codprod y codcli
                const updatedCatalogoConfigData = state.catalogoconfigdata.map((item:any) => {
                    if (item.codprod === data.codprod && item.codcli === data.codcli) {
                        // Actualizar min y max si se proporcionan en data
                        if (data.min !== undefined) {
                            item.min = data.min;
                        }
                        if (data.max !== undefined) {
                            item.max = data.max;
                        }
                    }
                    return item;
                });

                // Actualizar el estado con el nuevo objeto
                commit("setCatalogoConfigData", updatedCatalogoConfigData);
            } else {
                // Manejar errores de respuesta de la API
                commit(
                    "setErrorMessage",
                    "An error occurred while updating data. Please try again."
                );
            }
        } catch (err) {
            // Handle error
            commit(
                "setErrorMessage",
                "An error occurred while fetching data. Please try again."
            );

            console.error(err);
        } finally {
            commit("setLoading", false);
        }
    },


};
