import Swal from "sweetalert2";
import store from '@/store';
import pedidos, { PedidosFilters } from '@/store/pedidos';
import { Axios } from "axios";


export async function getFiltros(axios: Axios, apiRoute: string, isPedido: boolean=false){
    try {
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const response = axios.get(`${apiRoute}/pedido/filtros`, {
            headers
        })

        return response;
    } catch (err) {
        console.log(err);
    }
}

function setOptionsMarcaHtml(data: any) {
    let marcas = data.marcas;
    if (data?.checkboxStatesMarca?.length > 0) {
        marcas = data.checkboxStatesMarca;
    }

    let html = `
    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <input type="checkbox" class="mr-2" value="selectAllOptions" id="selectAllOptionsMarca" checked>
            <h2 class="my-2 text-lg font-semibold mb-2">Selecciona las marcas:</h2>
        </div>
        <div class='pr-1'>
            <button title="Seleccionar todos" id="selectAllButtonMarca" class="text-blue-500 hover:text-blue-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-check inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Seleccionar Todos -->
            </button>
            <button title="Deseleccionar todos" id="deselectAllButtonMarca" class="text-red-500 hover:text-red-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-minus inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Deseleccionar Todos -->
            </button>
        </div>
    </div>

    <!-- Add a wrapper div around the input element -->
    <div class="my-2 flex items-center border border-gray-300 rounded">
        <!-- Add the BoxIcon search icon -->
        <i class='bx bx-search text-gray-400 ml-2'></i>
        <!-- Add some classes to the input element to style it -->
        <input
            type="text"
            id="filterMarcasInput"
            placeholder="Filtrar marcas..."
            class="w-full py-1 px-2 outline-none"
        >
    </div>


    <div class="max-h-40 overflow-auto">
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-2" id="marcasContainer">`;

    marcas.map((item: any) => {
        html += `
                <label class="flex items-center text-left">
                    <input type="checkbox" class="mr-2" value="${item?.marca ?? item?.name}" name="checkboxOptionMarca" ${item?.checked === true ? 'checked' : ''}>
                    ${item?.marca ?? item?.name}
                </label>
                `;
    });

    html += `
        </div>
    </div>`;

    return html;
}

function setSelectedEstadoHtml(data: any) {
    // Crea un arreglo de opciones <option> basado en data.estados
    const optionsHtml = data.estados.map((estado: any) => {
        return `<option value="${estado.estado}">${estado.estado}</option>`;
    }).join(''); // Convierte el arreglo de opciones en una cadena

    // Crea el elemento select con las opciones
    const selectHtml = `
    <label class="block font-bold mb-1">Elige un estado:</label>
    <select class="border rounded-lg p-2 bg-white shadow-md focus:outline-none focus:ring-2 focus:ring-blue-600">
        <option value="" class="text-gray-500">TODOS</option>
        ${optionsHtml}
    </select>

    `;

    return selectHtml;
}

function setDateRange() {
    const dateRangeHtml = `
      <div class="grid grid-cols-2 gap-4">
        <div>
          <label class="block text-sm font-medium text-gray-700">Fecha Inicial</label>
          <input
            type="date"
            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400"
            id="fechaInicial"
          />
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Fecha Final</label>
          <input
            type="date"
            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400"
            id="fechaFinal"
          />
        </div>
      </div>
    `;

    return dateRangeHtml;
}

function setHtml(data: any) {
    const html = `
    <div class="grid gap-4 grid-cols-1">
        <div grid gap-4 grid-cols-2>
            <div>
                ${setSelectedEstadoHtml(data)}
            </div>
            <div>
                ${setDateRange()}
            </div>

        </div>
        <div >
            ${setOptionsMarcaHtml(data)}
        </div>
    </div>
`;

    return html;
}

export function openFiltrosPedidoModal(axios: Axios, apiRoute: string, isPedido: boolean = false) {
    Swal.showLoading();

    getFiltros(axios, apiRoute).then((response: any) => {
        const html = setHtml(response.data);
        Swal.fire({
            focusConfirm: false,
            title: 'Opciones de Filtrado',
            html,
            showCancelButton: true,
            confirmButtonText: 'Aplicar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-confirm-button',
                popup: 'swal2-popup swal2-modal swal2-show swal-wide'
            },
            didOpen() {
                // Obtiene los valores de filtro del estado de la aplicación
                const selectedFilters: PedidosFilters = pedidos.state.selectedFilters;

                // Configura los elementos del modal con los valores de filtro recuperados
                const selectEstado = document.querySelector('select') as HTMLSelectElement;
                if (selectedFilters.selectedEstado) selectEstado.value = selectedFilters.selectedEstado;

                const fechaInicialInput = document.getElementById('fechaInicial') as HTMLInputElement;
                if (selectedFilters.fechaInicial) {
                    fechaInicialInput.value = selectedFilters.fechaInicial;
                }

                const fechaFinalInput = document.getElementById('fechaFinal') as HTMLInputElement;

                if (selectedFilters.fechaFinal) {
                    fechaFinalInput.value = selectedFilters.fechaFinal;
                }

                const filterInputMarcas = document.getElementById('filterMarcasInput') as HTMLInputElement;
                const marcasContainer = document.getElementById('marcasContainer') as HTMLElement;

                const selectAllButtonMarca = document.getElementById('selectAllButtonMarca') as HTMLInputElement;
                const deselectAllButtonMarca = document.getElementById('deselectAllButtonMarca') as HTMLInputElement;
                const optionCheckboxesMarca = document.querySelectorAll('input[type="checkbox"][name^="checkboxOptionMarca"]') as NodeList;
                const checkboxStatesMarca = [];

                optionCheckboxesMarca.forEach((checkbox: any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.name,
                        checked: checkbox.checked
                    });
                });

                selectAllButtonMarca.addEventListener('click', () => {
                    optionCheckboxesMarca.forEach((checkbox: any) => {
                        checkbox.checked = true;
                    });
                });

                deselectAllButtonMarca.addEventListener('click', () => {
                    optionCheckboxesMarca.forEach((checkbox: any) => {
                        checkbox.checked = false;
                    });
                });

                // Add an event listener to the filter input element to handle user input
                filterInputMarcas.addEventListener('input', (event: any) => {
                    // Get the current value of the filter input
                    const filterValue = event.target.value.toLowerCase();

                    // Get all label elements within the marcas container
                    const labels = marcasContainer.querySelectorAll('label');

                    // Loop over each label element
                    labels.forEach((label: any) => {
                        // Get the text content of the label
                        const labelText = label.textContent.toLowerCase();

                        // Check if the label text includes the filter value
                        if (labelText.includes(filterValue)) {
                            // If it does, show the label
                            label.style.display = 'flex';
                        } else {
                            // If it doesn't, hide the label
                            label.style.display = 'none';
                        }
                    });
                });
            },
            preConfirm: () => {
                // Obtén el elemento select
                const selectEstado = document.querySelector('select'); // Esto seleccionará el primer select en tu modal, asegúrate de que sea el correcto
                let selectedEstado:string="";

                if (selectEstado) {
                    selectedEstado = selectEstado.value;
                }

                // Obtén las fechas seleccionadas
                const fechaInicialElement = document.getElementById('fechaInicial') as HTMLInputElement;
                const fechaInicial = fechaInicialElement.value;

                const fechaFinalElement = document.getElementById('fechaFinal') as HTMLInputElement;
                const fechaFinal = fechaFinalElement.value;

                const checkOptionsMarcaElement = document.getElementById('selectAllOptionsMarca') as HTMLInputElement;
                const checkOptionsMarca = checkOptionsMarcaElement.checked;
                const optionCheckboxesMarca = document.querySelectorAll('input[type="checkbox"][name^="checkboxOptionMarca"]') as NodeList;
                const checkboxStatesMarca: any = [];


                optionCheckboxesMarca.forEach((checkbox: any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.value,
                        checked: checkbox.checked
                    });
                });

                return {
                    selectedEstado,
                    checkOptionsMarca,
                    checkboxStatesMarca,
                    fechaInicial,
                    fechaFinal,
                };
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                store.dispatch('pedidos/setSelectedFilters', {
                    checkOptionsMarca: result.value.checkOptionsMarca,
                    checkboxStatesMarca: result.value.checkboxStatesMarca,
                    selectedEstado: result.value.selectedEstado,
                    fechaFinal: result.value.fechaFinal,
                    fechaInicial: result.value.fechaInicial
                });

                store.dispatch('pedidos/getPedidos', {
                    axios,
                    apiRoute,
                    searchParams: {
                        showPedidos: isPedido,
                        filtro: null,
                        ...result.value
                    },
                })
            }
        });
    });

}