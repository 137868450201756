import getPrecioNeto from "./getPrecioNeto";
import getPrecioMolecula from "./getPrecioMolecula";
import getMejorPrecioMolecula from "./getMejorPrecioMolecula";
import formatCurrency from "./formatCurrency";

const prices = {
    getPrecioNeto,
    getPrecioMolecula,
    getMejorPrecioMolecula,
    formatCurrency
}

export default prices;
