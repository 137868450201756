import Swal from "sweetalert2";

async function downloadPDF(marca:any, axios:any, apiRoute:any, items:any) {
    try {
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };


        const data = {
            items,
            marca
        }

        const response = await axios.post(apiRoute + '/pedido/generate-order-pdf', data, {
            headers: headers,
            responseType: 'blob', // Set the response type to 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        let filename = ``;

        if (marca === "") {
            filename = `PEDIDO_MAESTRO_${Date.now().toString()}`;
        } else {
            const id = items.find((item:any) => item.marca === marca)?.id;
            if (id) {
                filename = `PEDIDO_${marca}_${id}_${Date.now().toString()}`;
            }
        }
        link.setAttribute('download', filename + '.pdf'); // Set the filename
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error(error);
    }
}

export default function openDownloadModal(marcas:any, axios:any, apiRoute:any, data:any) {
    const LastBranchSelected:string|null = localStorage.getItem('LastBranchSelected');
    let html = `
    <select id="marca" class="swal2-input p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500">
    <option value="">PEDIDO COMPLETO</option>
    `;

    marcas.forEach((element:any) => {
        html += `
        <option value="${element}"  ${element === LastBranchSelected ? 'selected' : ''}>${element}</option>
        `;
    });

    html += `
    </select>
    `;
    Swal.fire({
        title: 'Descargar Pedido PDF',
        html,
        showCancelButton: true,
        confirmButtonText: 'Descargar',
        reverseButtons: true,
        focusConfirm: false,
        customClass:{
            confirmButton: 'custom-confirm-button'
        },
        preConfirm: () => {
            const marcaElement = document.getElementById('marca') as HTMLInputElement;
            if (marcaElement) {
              const marca:any = marcaElement.value;
              downloadPDF(marca, axios, apiRoute, data);
            } else {
              // Maneja el caso en que el elemento no se encontró
            }

        },
    });
}