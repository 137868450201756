<template>

        <vue-sidebar-menu-akahon
        class="max-h-[100vh]"
        :isUsedVueRouter="true"
        @menu-item-clicked="fnRouteTo(link)"
        menuTitle="Menu" menuIcon="icompras-icon" :menuItems="items" itemsTooltipColor="#ffffff"
            bgColor="#003053" secondaryColor="#001f36" :isSearch=false profileImg="" profileName="ICOMPRAS360"
            profileRole="DISTRIBUIDORES" :isExitButton=false />
</template>

<script>

export default {
    name: 'AppSidebar',
    components: { },
    props: ['options', 'items', 'user'],
    data() {

        return {

        }
    }
}
</script>

<style></style>
