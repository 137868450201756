// store/orders/actions.js
import functions from "@/functions";
import { OrdersState, RESET_CART_ITEMS } from "./index";
import { storePedidoItem, pedidosItem, PedidosData, PedidoSelectedFilters } from "@/interfaces/Pedidos";
import { Commit } from "vuex";
import { Axios } from "axios";
import prices from "@/functions/prices";

const actions = {
    resetCartItems({ commit }: { commit: Commit }) {
        commit(RESET_CART_ITEMS); // Commit the mutation to reset cartItems
    },

    async removeOneFromCart(
        { commit }: { commit: Commit },
        {
            index,
            product,
            axios,
            apiRoute,
        }: { index: number; product: pedidosItem; axios: Axios; apiRoute: string }
    ) {
        const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const data = {
            id: product.id,
            item: product.item,
        };

        // Make an API request to store the pedido with the token in headers
        await axios.delete(apiRoute + "/pedido/destroyOnePedren", {
            data: data, // The data you want to send in the request body
            headers: headers, // Optional headers
        });

        commit("removeOneFromCart", index);
    },

    async removeFromCart(
        { commit }: { commit: Commit },
        {
            index,
            order,
            axios,
            apiRoute,
        }: { index: number; order: pedidosItem; axios: Axios; apiRoute: string }
    ) {
        const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const data = {
            id: order.id,
        };

        await axios.delete(apiRoute + "/pedido/destroy", {
            data: data, // The data you want to send in the request body
            headers: headers, // Optional headers
        });

        commit("removeFromCart", index);
    },

    startNewOrder({ commit }: { commit: Commit }) {
        commit("createNewOrder");
    },

    async addToCart(
        { commit }: { commit: Commit },
        {
            axios,
            apiRoute,
            data,
        }: { axios: Axios; apiRoute: string; data: storePedidoItem }
    ) {
        try {
            const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

            // Configure headers with the token
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            // Make an API request to store the pedido with the token in headers
            const response = await axios.post(apiRoute + "/pedido/store", data, {
                headers: headers,
            });

            // Commit the addToCart mutation or take any other action based on the response
            commit("addToCart", data);

            console.log("Pedido added to cart:", response.data);
        } catch (error) {
            console.error("Error adding pedido to cart:", error);

            // Handle errors here
        }
    },

    async updateCartItemQuantity(
        { commit, state }: { commit: Commit; state: OrdersState },
        {
            product,
            quantity,
            axios,
            apiRoute,
        }: {
            product: storePedidoItem;
            quantity: number;
            axios: Axios;
            apiRoute: string;
        }
    ) {
        const existingCartItem = state.cartItems.items.find(
            (item: pedidosItem) => item.codprod === product.codprod
        );

        if (existingCartItem) {
            // If the item already exists in the cart, update the quantity

            const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

            // Configure headers with the token
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const data = {
                ...product,
                quantity: quantity,
            };

            // Make an API request to update the item's quantity with the token in headers
            const response = await axios.post(apiRoute + "/pedido/store", data, {
                headers: headers,
            });

            // Commit a mutation to update the item's quantity in the store
            commit("updateItemQuantity", {
                codprod: data.codprod,
                quantity: data.quantity,
            });

            console.log("Quantity updated for item:", response.data);
        }
    },

    async setItemDiscountMarca(
        { commit, state }: { commit: Commit; state: OrdersState },
        {
            marca,
            discount,
            axios,
            apiRoute,
        }: { marca: string; discount: number; axios: Axios; apiRoute: string }
    ) {
        // Find items in the cart with the matching 'marca'
        const cartItemsToApplyDiscount = state.cartItems.items.filter(
            (item: any) => item.marca === marca
        );

        // Make an API request to store the pedido with the updated items
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Update 'discountMarca' and 'neto' for each matching item
        const updatedItems = cartItemsToApplyDiscount.map((item: any) => {
            // Make an API request to store the pedido with the token in headers
            item.discountMarca = discount;
            const data = {
                ...item,
                neto: prices.getPrecioNeto(item, undefined, true, true, true)
            }
            return data;

        });

        await axios.post(apiRoute + '/pedido/store', updatedItems, {
            headers: headers,
        });

        // Commit the mutation to update the state
        commit("setItemDiscountMarca", { cartItemsToApplyDiscount, marca, discount });
    },

    async updateCartItemDiscount(
        { commit, state }: { commit: Commit; state: OrdersState },
        {
            product,
            discount,
            axios,
            apiRoute,
        }: {
            product: storePedidoItem;
            discount: number;
            axios: Axios;
            apiRoute: string;
        }
    ) {
        const existingCartItem = state.cartItems.items.find(
            (item: pedidosItem) => item.codprod === product.codprod
        );

        if (existingCartItem) {
            // If the item already exists in the cart, update the quantity

            const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

            // Configure headers with the token
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const data = {
                ...product,
                discountItem: discount
            };

            // Make an API request to store the pedido with the token in headers
            await axios.post(apiRoute + "/pedido/store", data, {
                headers: headers,
            });

            commit("updateItemDiscount", {
                codprod: data.codprod,
                discount: data.discountItem,
            });

        }
    },

    async fetchCartItemsQuantity(
        { commit }: { commit: Commit },
        { axios, apiRoute }: { axios: Axios; apiRoute: string }
    ) {
        try {
            const token = localStorage.getItem("authToken");
            if (!token) {
                throw new Error("Authentication token not found");
            }

            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.get(`${apiRoute}/pedido/obtainQuantity`, {
                headers: headers,
            });
            if (response.status === 200 && Array.isArray(response.data.items)) {
                const items = response.data.items;
                commit("updateCartItemsQuantity", items); // Commit a new mutation to update cartItemsQuantity
            }

            return response.data;
        } catch (error) {
            console.error("Error fetching cart items:", error);
            // Handle errors here, such as showing a notification to the user
        }
    },

    async fetchCartItems(
        { commit }: { commit: Commit },
        { axios, apiRoute, branch }: { axios: Axios; apiRoute: string, branch: string },
    ) {
        try {
            const token = localStorage.getItem("authToken");
            if (!token) {
                throw new Error("Authentication token not found");
            }

            const obj = {
                marca: branch
            }
            const response = await axios.post(`${apiRoute}/pedido/show`, obj, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200 && Array.isArray(response.data.items)) {
                const items = response.data;
                // Calculate cartItemsQuantity based on fetched cart items
                commit("updateCartItems", items); // Commit a new mutation to update cartItemsQuantity
            }

            return response.data.items;
        } catch (error) {
            console.error("Error fetching cart items:", error);
            // Handle errors here, such as showing a notification to the user
        }
    },

    async sendEmail(
        { commit }: { commit: Commit },
        {
            email,
            comment,
            data,
            axios,
            apiRoute,
            unds,
            renglones,
            total,
            ahorro,
        }: {
            email: string;
            comment: string;
            data: PedidosData;
            axios: Axios;
            apiRoute: string;
            unds: number;
            renglones: number;
            total: number;
            ahorro: number;
        }
    ) {
        try {
            const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

            // Configure headers with the token
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const emailData = {
                email: email,
                comment: comment,
                items: [...data],
                marca: data[0].marca,
                unds,
                renglones,
                total,
                ahorro: total - ahorro,
            };
            await axios.post(
                apiRoute + "/pedido/sendEmail",
                emailData,
                {
                    headers: headers,
                }
            );

            commit("removeFromCart", emailData.marca);

        } catch (error) {
            console.log(error);
        }
    },

    async generateSuggested(
        { commit }: { commit: Commit },
        { axios, apiRoute, data }: { axios: Axios; apiRoute: string; data: PedidoSelectedFilters }
    ) {
        const token = localStorage.getItem("authToken");

        try {
            const response = await axios.post(
                `${apiRoute}/pedido/sugerido`,
                { ...data },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const items = response.data.items;
                commit("updateCartItemsQuantity", items);
            }
        } catch (error) {
            console.log(error);
        }
    },
};

export default actions;
