export default function DiferenciaDias(fechaini:any, fechav:any) {

    const fechoy = Date.parse(fechaini);

    const fecven = new Date(fechav).getTime();
    const dif = fechoy - fecven;
    let dias = dif / (1000 * 60 * 60 * 24);

    if (dias < 0) {
        dias = 0;
    }
    return Math.ceil(dias);
}