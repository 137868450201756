import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import Views from '../views';
const { CatalogoView, LoginView, WelcomeView, PedidoView, NotFoundView, SellersView, PedidoListView, CatalogoConfigView, ProfileView, UserInfoView } = Views;

const routes:Array<RouteRecordRaw> = [
    {
        path: "/",
        component: LoginView,
    },
    {
        path: "/inicio",
        component: WelcomeView,
    },
    {
        path: "/catalogo",
        component: CatalogoView,
    },
    {
        path: "/catalogo-config",
        component: CatalogoConfigView,
    },
    {
        path: "/pedido",
        component: PedidoView,
    },
    {
        path: "/vendedores",
        component: SellersView,
    },
    {
        path: "/lista-pedidos",
        component: PedidoListView,
    },
    {
        path: "/perfil",
        component: ProfileView,
    },
    {
        path: "/informacion-usuario",
        component: UserInfoView,
    },
    {
        path: "/page-not-found",
        component: NotFoundView,
    },
    { path: "/:pathMatch(.*)*", redirect: "/page-not-found" },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    linkActiveClass: "active",
});

export default router;
