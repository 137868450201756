import store from "@/store";
import jwt_decode from 'jwt-decode';

export function ProcessUserData() {
    const userGetterValue = store.getters['auth/GET_USER'];
    if(!userGetterValue){
        return null;
    }
    const u = JSON.parse(userGetterValue);
    const token = localStorage.getItem('authToken');
    let decoded = null;
    if(token) {
        decoded = jwt_decode(token)
    }
    let sucu;

    if (u.ultcodcli && u.tipo == "G") {
        sucu = userGetterValue.detalles.filter((item:any) => item.codcli == u.ultcodcli)[0];
    }

    if (u.tipo == "P") {
        sucu = {
            nomcli: u.detalles.codsede,
            codmone: u.detalles.CodMoneda,
        };
    }

    u.id_tipo = u.tipo;
    u.tipo = showType(u.tipo);

    u.created_at = formatDate(u.created_at);

    const dataUser = {
        ...u,
        ...sucu,
        token,
        decoded
    }
    return dataUser;
}

function showType(type:string) {
    switch (type) {
        case "A":
            return "ADMINISTRADOR";
        case "G":
            return "GRUPO";
        case "P":
            return "PROVEEDOR";
    }
}

function formatDate(date:string) {
    // Crear una instancia de Date a partir de la cadena de fecha ISO 8601
    const parsedDate = new Date(date);

    // Obtener el día, mes y año de la fecha
    const day = parsedDate.getUTCDate();
    const month = parsedDate.getUTCMonth() + 1; // Agregar 1 porque enero es 0
    const year = parsedDate.getUTCFullYear();

    // Obtener la hora y el minuto de la fecha
    const hours = parsedDate.getUTCHours();
    const minutes = parsedDate.getUTCMinutes();

    // Formatear día, mes, hora y minuto para que tengan dos dígitos
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Crear la cadena de fecha en el formato "d-m-y H:i"
    const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;

    return formattedDate;
  }