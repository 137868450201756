<template>
    <div @click="closeMenu">
        <div class="topnav">
            <div class="inside-nav">
                <!-- SUCURSAL -->
                <a class="text-left" v-if="data_user?.tipo == 'C' || data_user?.id_tipo == 'P'">
                    <p class="text-xs flex flex-col text-left">
                        <span class="flex"><i class="bx bx-building mr-1.5"></i>Sucursal </span><span>{{ sucursal_selected
                        }}</span>
                    </p>
                </a>

                <!-- FECHA DE SINCRONIZACION -->
                <a class="text-left">
                    <p class="text-xs flex flex-col text-left">
                        <span class="flex"><i class="bx bxs-calendar mr-1.5"></i>Sincronizado</span>
                        <span>{{ data_user.detalles.fechasinc }}</span>
                    </p>
                </a>

                <!-- Cart Icon -->
                <div v-if="!isCartEmptyComputed" class="flex bg-color-barrasuperior z-20" @click="goToPedido">
                    <!-- Use the computed property isCartEmptyComputed to show or hide the cart icon -->

                    <div class="flex content-center flex-wrap down-nav">
                        <div @click="toggleCartVisibility" class="cart-icon" v-tooltip="{ content: 'Ver carrito', triggers: ['hover'], autoHide: true }">
                            <!-- Add a click event handler to toggle the cart visibility -->
                            <!-- Add your cart icon here -->
                            <!-- For example: -->
                            <i class='bx bxs-cart-alt bx-lg'></i>
                            <div v-if="cartItemCount" class="cart-count">
                                {{ cartItemCount }}
                            </div>
                        </div>
                    </div>


                </div>

                <!-- USUARIO -->
                <a class="text-left bg-color-barrasuperior z-10">
                    <p class="text-xs flex flex-col text-left">
                        <span class="flex w-max"><i class="bx bx-user-circle mr-1.5"></i>{{ data_user?.name }}
                        </span><span>{{ data_user?.tipo }}</span>
                    </p>
                </a>

                <!-- MENU -->
                <a class="bg-color-barrasuperior z-10" role="button" id="toggle-nav-menu"
                    @click="isOptionsExpanded = !isOptionsExpanded" @blur="isOptionsExpanded = false"><i
                        class="bx bx-menu icon-style"></i></a>
                <transition enter-active-class="transform transition duration-500 ease-custom"
                    enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                    enter-to-class="translate-y-0 scale-y-100 opacity-100"
                    leave-active-class="transform transition duration-300 ease-custom"
                    leave-class="translate-y-0 scale-y-100 opacity-100"
                    leave-to-class="-translate-y-1/2 scale-y-0 opacity-0">
                    <ul v-show="isOptionsExpanded" ref="dropdown_menu"
                        class="absolute left-0 right-0 mb-4 bg-white divide-y rounded-lg shadow-lg overflow-hidden mt-16 list">
                        <li v-for="(option, index) in options" :key="index"
                            class="px-3 py-2 duration-300 hover:bg-gray-200 flex" @mousedown.prevent="setOption(option)"
                            role="button">
                            <i class="mt-1 mr-2" :class="option.icon"></i>
                            <div>{{ option.name }}</div>
                        </li>
                        <hr />
                        <li v-for="(hoption, index) in hoptions" :key="index"
                            class="hide-li px-3 py-2 duration-300 hover:bg-gray-200 flex"
                            @mousedown.prevent="setOption(hoption)" role="button">
                            <i class="mt-1 mr-2" :class="hoption.icon"></i>
                            <div>{{ hoption.name }}</div>
                        </li>
                    </ul>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import { Ripple, initTE } from "tw-elements";

export default {
    name: "AppNavbar",
    components: {},
    props: ["options", "hoptions", "fechaSincronizado"],
    mounted() {
        initTE({ Ripple });
    },
    computed: {
        ...mapState("orders", ["cartItems", "cartItemsQuantity"]), // Map the cartItems state from the "orders" module
        isCartEmptyComputed() {
            return this.cartItemsQuantity === 0;
        },

        cartItemCount() {
            return this.cartItemsQuantity < 0 ? 0 : this.cartItemsQuantity; // Return the cart item count
        },
    },
    data() {
        const userData = this.$functions.auth.ProcessUserData();

        return {
            isOptionsExpanded: false,
            selectedOption: "",
            data_user: userData, // Initialize as null
            sucursal_selected: userData.nomcli,
            isCartVisible: false,
        };
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        setOption(option) {
            switch (option.name) {
                case "Cerrar Sesión":

                        Swal.fire({
                            title: '¿Estás seguro?',
                            text: '¿Estás seguro de que deseas cerrar sesión?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Sí, cerrar sesión',
                            cancelButtonText: 'Cancelar',
                            reverseButtons: true,
                            focusConfirm: false,
                            customClass: {
                                confirmButton: 'custom-confirm-button', // Apply the custom class to the confirm button
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // Llamada a la acción de logout después de confirmar
                                this.logout({
                                    axios: this.$axios,
                                    apiRoute: this.$apiRoute,
                                    router: this.$router,
                                });
                            }
                        });


                    break;
                case "Catalogo":
                    this.$router.push("/catalogo");
                    break;
                case "Vendedores":
                    this.$router.push("/vendedores");
                    break;
                case "Pedidos":
                    this.$router.push("/lista-pedidos");
                    break;
                case "Perfil":
                    this.$router.push("/perfil");
                    break;
                case "Inicio":
                    this.$router.push("/inicio");
                    break;
            }
            this.isOptionsExpanded = false;
        },
        goToPedido() {
            this.$router.push('/pedido');
        },
        toggleCartVisibility() {
            this.isCartVisible = !this.isCartVisible; // Toggle the cart visibility
        },
        closeMenu(event) {
            // Check if the click occurred on the menu toggle button
            if (
                event.target.closest("#toggle-nav-menu") ||
                event.target.closest("#toggle-nav-money")
            ) {
                return;
            }

            // Check if the dropdown menu element exists
            if (this.$refs.dropdown_menu) {
                // Check if the click occurred outside of the dropdown menu element
                if (!this.$refs.dropdown_menu.contains(event.target)) {
                    // Close the dropdown menu
                    this.isOptionsExpanded = false;
                }
            }

            // Check if the dropdown menu element exists
            if (this.$refs.dropdown_money) {
                // Check if the click occurred outside of the dropdown menu element
                if (!this.$refs.dropdown_money.contains(event.target)) {
                    // Close the dropdown menu
                    this.showMoneyOptions = false;
                }
            }
        },

    },
};
</script>

<style>
.down-nav>.down {
    top: 55px !important;
    z-index: 99 !important;
}

.down-nav {
    font-size: 30px;
}

.cart-icon {
    position: relative;
    cursor: pointer;
}

.cart-count {
    position: absolute;
    top: -4px;
    right: -8px;
    background-color: #ff5a5f;
    color: white;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional hover effect */
.cart-icon:hover>.cart-count {
    background-color: #e53e42;
}
</style>