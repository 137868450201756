<template>
    <div class="mb-3">
        <div class="relative mb-4 flex w-full flex-wrap items-stretch">
            <input type="search"
                class="relative m-0 block w-[1px] min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                :placeholder=placeholder aria-label="Search" aria-describedby="button-addon2"
                @keyup.enter="onKeyUp($event)" />

            <!--Search icon-->
            <span
                class="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-neutral-700 dark:text-neutral-200 icon-style"
                id="basic-addon2" role="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
                    <path fill-rule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clip-rule="evenodd" />
                </svg>
            </span>

            <span
                class="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-neutral-700 dark:text-neutral-200 icon-style"
                id="basic-addon2" role="button" @click="$emit('open-modal')" v-tooltip="{ content: 'Filtros de busqueda', triggers: ['hover'], autoHide: true }">
                <svg xmlns="http://www.w3.org/2000/svg" height="0.8em" viewBox="0 0 512 512" style="fill:#ffffff">
                    <path
                        d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
                </svg>
            </span>

            <span v-if="showSugLocal == true"
            v-tooltip="{ content: 'Marcar por sugeridos', triggers: ['hover'], autoHide: true  }"
                class="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-neutral-700 dark:text-neutral-200 icon-style"
                id="basic-addon2" role="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    style="fill:#ffffff; transform: ;msFilter:;">
                    <path
                        d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z">
                    </path>
                    <path
                        d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm8.933 3.519-1.726-1.726-1.414 1.414 3.274 3.274 5.702-6.84-1.538-1.282z">
                    </path>
                </svg>
            </span>

            <span v-if="showInfoLocal == true"
            v-tooltip="{ content: 'Informacion de uso', triggers: ['hover'], autoHide: true}"
                class="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-neutral-700 dark:text-neutral-200 icon-style"
                id="basic-addon2" role="button" @click="$emit('open-modal-info')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    style="fill:#ffffff; transform: ;msFilter:;">
                    <circle cx="12" cy="12" r="11" fill="none" stroke="#ffffff" stroke-width="2" />
                    <!-- Círculo con borde blanco -->
                    <path
                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z">
                    </path>
                </svg>
            </span>


            <span v-if="from == 'catalogo'"
            v-tooltip="{ content: `${nameConfig}`, triggers: ['hover'], autoHide: true }"
                class="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-neutral-700 dark:text-neutral-200 icon-style"
                role="button" @click="$emit('push-config-view')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    style="fill:#ffffff; transform: ;msFilter:;">
                    <path
                        d="m2.344 15.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V21a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.094 8.094 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.683 7.683 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.034 8.034 0 0 0 15 4.598V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 7.105 5.71L5.71 4.904a.999.999 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z">
                    </path>
                </svg>

            </span>


            <div v-if="showPedLocal == true"
            v-tooltip="{ content: 'Generar pedido sugerido', triggers: ['hover'], autoHide: true }"
                class="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-black dark:text-neutral-200 bg-gray-100 hover:bg-gray-200 cursor-pointer font-bold"
                role="button" @click="this.$functions.modals.openSugeridoModal($axios, $apiRoute)">
                <span class="text-black">Generar sugerido</span>
            </div>




        </div>
    </div>
</template>


<script>
export default {
    name: "AppSearchInput",
    props: ['placeholder', 'showInfo', 'from', 'showSug', 'showPedSug'],
    data() {
        return {
            nameConfig: 'Configuración de ' + this.from,
            showInfoLocal: this.showInfo ?? false,
            showSugLocal: this.showSug ?? false,
            showPedLocal: this.showPedSug ?? false
        }
    },
    methods: {
        onKeyUp(e) {
            e.preventDefault();
            if (e.target.value === '') {
                this.$emit('erase');
            }
        }

    }
}
</script>