<template>
    <div class="justify-between" :class="cardSize" ref="card">
        <div :class="cardClass" class="rounded-b p-4 flex flex-col leading-normal " role="button">
            <div class="mb-2">
                <div class="font-bold text-xl justify-between flex" :class="headerClass">{{ title }}
                    <span><i :class='icon'></i></span>
                </div>
                <p class="text-base">{{ content }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CardOption',
    props: ['title', 'content', 'cardClass', 'icon', 'cardSize', 'headerClass'],
    components: {
    },
    data() {
        return {}
    },
    mounted() {

    }
}
</script>

<style></style>
