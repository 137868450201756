import Swal from 'sweetalert2';

export default function openMoleculasModal(molecula: any, barra: any, axios: any, apiRoute: any) {

    if (molecula != 'POR DEFINIR') {
        Swal.fire({
            allowOutsideClick: false,
            focusConfirm: false,
            didOpen: () => {
                Swal.showLoading();
                const token = localStorage.getItem(`authToken`);

                axios.get(`${apiRoute}/molecule-comparison`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: { molecula }
                })
                    .then((response: any) => {
                        // La petición fue exitosa, muestra el contenido de la respuesta
                        const ordered_data = response.data[0].data;

                        // Sort the array with 'true' values first
                        ordered_data.sort((a: any, b: any) => {
                            if (a.costo && !b.costo) {
                                return -1; // 'a' should come before 'b'
                            } else if (!a.costo && b.costo) {
                                return 1; // 'b' should come before 'a'
                            }
                            return a.costo - b.costo; // Keep the order unchanged
                        });

                        const title = response.data[0].molecula;

                        let html = `
                        <div class="custom-swal-container">
                        <table class='table-auto w-full'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>MARCA</th>
                                    <th>BARRA</th>
                                    <th>COSTO</th>
                                    <th>UNIDAD MOLECULA</th>
                                    <th>COSTO MOLECULA</th>
                                </tr>
                            </thead>
                            <tbody>`;

                        ordered_data.forEach((element: any, index: any) => {
                            let clase;
                            if (element.min_price) {
                                clase = `class='bg-green-200'`;
                            } else {
                                if (element.barra == barra) {
                                    clase = `class='bg-red-200'`;
                                }
                            }



                            html += `
                                <tr ${clase}>
                                    <td>${index + 1}</td>
                                    <td>${element.marca}</td>
                                    <td>${element.barra}</td>
                                    <td>${element.costo}</td>
                                    <td>${element.unidadmolecula}</td>
                                    <td>${element.precio_molecula}</td>
                                </tr>`;
                        });

                        html += `
                            </tbody>
                        </table>
                        </div>
                        `;
                        Swal.fire({
                            title: `Comparativo de moleculas - MOLECULA: ${title}`,
                            html,
                            showCloseButton: true,
                            showConfirmButton: false,
                            focusConfirm: false,
                            customClass: {
                                popup: 'swal2-popup swal2-modal swal2-show swal-wide'
                            }
                        });
                    })
                    .catch((error: any) => {
                        // La petición falló, muestra detalles del error si disponibles
                        const errorMessage = error.response ? error.response.data : 'La petición falló.';
                        Swal.fire({
                            focusConfirm: false,
                            title: 'Error',
                            text: errorMessage,
                            icon: 'error',
                            showConfirmButton: false
                        });
                    });
            }
        })
    }
}
