import Swal from 'sweetalert2';
import {getFiltros} from './openFiltrosPedidoModal';
import store from "@/store";
import { Axios } from 'axios';

function setOptionsMarcaHtml(data:any) {
    let marcas = data.marcas;
    if (data?.checkboxStatesMarca?.length > 0) {
        marcas = data.checkboxStatesMarca;
    }

    let html = `
    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <input type="checkbox" class="mr-2" value="selectAllOptions" id="selectAllOptionsMarca" checked>
            <h2 class="my-2 text-lg font-semibold mb-2">Selecciona las marcas:</h2>
        </div>
        <div class='pr-1'>
            <button title="Seleccionar todos" id="selectAllButtonMarca" class="text-blue-500 hover:text-blue-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-check inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Seleccionar Todos -->
            </button>
            <button title="Deseleccionar todos" id="deselectAllButtonMarca" class="text-red-500 hover:text-red-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-minus inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Deseleccionar Todos -->
            </button>
        </div>
    </div>

    <!-- Add a wrapper div around the input element -->
    <div class="my-2 flex items-center border border-gray-300 rounded">
        <!-- Add the BoxIcon search icon -->
        <i class='bx bx-search text-gray-400 ml-2'></i>
        <!-- Add some classes to the input element to style it -->
        <input
            type="text"
            id="filterMarcasInput"
            placeholder="Filtrar marcas..."
            class="w-full py-1 px-2 outline-none"
        >
    </div>


    <div class="max-h-40 overflow-auto">
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-2" id="marcasContainer">`;

    marcas.map((item:any) => {
        html += `
                <label class="flex items-center text-left">
                    <input type="checkbox" class="mr-2" value="${item?.marca ?? item?.name}" name="checkboxOptionMarca" ${item?.checked === true ? 'checked' : ''}>
                    ${item?.marca ?? item?.name}
                </label>
                `;
    });

    html += `
        </div>
    </div>`;

    return html;
}

function setMaximo() {
    return `
    <div class="flex items-center">
        <label for="maximo" class="block text-base font-bold text-neutral-700 dark:text-neutral-200 mr-2">Establecer días: </label>
        <input type="text" id="maximo"
            class="relative block w-1/2 min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset 0 0 0 1px rgba(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder-text-neutral-200 dark:focus:border-primary"/>
    </div>
    `;
}



function setHtml(data:any) {
    const html = `
    <div class="grid gap-4 grid-cols-1">
        ${setMaximo()}
        ${setOptionsMarcaHtml(data)}

    </div>
`;

    return html;
}

export default function openSugeridoModal(axios:Axios, apiRoute:string) {
    Swal.showLoading();

    getFiltros(axios, apiRoute).then((response:any) => {
        const html = setHtml(response.data);
        Swal.fire({
            focusConfirm: false,
            title: 'Opciones de sugerido',
            html,
            showCancelButton: true,
            confirmButtonText: 'Generar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            customClass: {
                confirmButton: 'custom-confirm-button',
                popup: 'swal2-popup swal2-modal swal2-show swal-wide'
            },
            didOpen() {

                const filterInputMarcas = document.getElementById('filterMarcasInput') as HTMLInputElement;
                const marcasContainer = document.getElementById('marcasContainer') as HTMLElement;

                const selectAllButtonMarca = document.getElementById('selectAllButtonMarca') as HTMLInputElement;
                const deselectAllButtonMarca = document.getElementById('deselectAllButtonMarca') as HTMLInputElement;
                const optionCheckboxesMarca = document.querySelectorAll('input[type="checkbox"][name^="checkboxOptionMarca"]') as NodeList;
                const checkboxStatesMarca = [];

                optionCheckboxesMarca.forEach((checkbox:any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.name,
                        checked: checkbox.checked
                    });
                });

                selectAllButtonMarca.addEventListener('click', () => {
                    optionCheckboxesMarca.forEach((checkbox:any) => {
                        checkbox.checked = true;
                    });
                });

                deselectAllButtonMarca.addEventListener('click', () => {
                    optionCheckboxesMarca.forEach((checkbox:any) => {
                        checkbox.checked = false;
                    });
                });

                // Add an event listener to the filter input element to handle user input
                filterInputMarcas.addEventListener('input', (event:any) => {
                    // Get the current value of the filter input
                    const filterValue = event.target.value.toLowerCase();

                    // Get all label elements within the marcas container
                    const labels = marcasContainer.querySelectorAll('label');

                    // Loop over each label element
                    labels.forEach((label:any) => {
                        // Get the text content of the label
                        const labelText = label.textContent.toLowerCase();

                        // Check if the label text includes the filter value
                        if (labelText.includes(filterValue)) {
                            // If it does, show the label
                            label.style.display = 'flex';
                        } else {
                            // If it doesn't, hide the label
                            label.style.display = 'none';
                        }
                    });
                });
            },
            preConfirm: () => {
                const checkOptionsMarcaElement = document.getElementById('selectAllOptionsMarca') as HTMLInputElement;
                const checkOptionsMarca = checkOptionsMarcaElement.checked;

                const inputMaximoElement = document.getElementById('maximo') as HTMLInputElement;
                const inputMaximo = inputMaximoElement.value;
                const optionCheckboxesMarca = document.querySelectorAll('input[type="checkbox"][name^="checkboxOptionMarca"]') as NodeList;
                const checkboxStatesMarca:any = [];


                optionCheckboxesMarca.forEach((checkbox:any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.value,
                        checked: checkbox.checked
                    });
                });

                // Validar que inputMaximo sea un número mayor que 0
                const parsedInputMaximo = inputMaximo == "" ? 0 : parseFloat(inputMaximo);
                if (isNaN(parsedInputMaximo) || parsedInputMaximo < 0) {
                    Swal.showValidationMessage('Por favor, ingrese un valor válido mayor que 0 en los dias.');
                    return false; // La validación falla, el modal no se cierra.
                }

                return {
                    inputMaximo: parsedInputMaximo,
                    checkOptionsMarca,
                    checkboxStatesMarca,
                };
            },
        }).then(result => {
            if (result.isConfirmed) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                // Inicia el timer del Toast al mostrarlo
                Toast.showLoading();

                store.dispatch('orders/generateSuggested', {
                    axios,
                    apiRoute,
                    data: result.value,
                }).then(() => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Se ha realizado la creacion del pedido sugerido exitosamente.'
                    })

                    store.dispatch('pedidos/getPedidos',{
                        axios,
                        apiRoute,
                        showPedidos: true
                    });
                });
            }
        });
    });
}
