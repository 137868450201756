import Swal from "sweetalert2";
import prices from '../../prices';

function getOne(axios:any, apiRoute:any, barra:any) {

    try {
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Definir los parámetros de la solicitud GET, incluyendo 'barra'
        const params = {
            barra: barra, // Puedes ajustar el nombre del parámetro según lo que espera la API
        };

        const response = axios.get(`${apiRoute}/cat/showOne`, {
            headers,
            params
        })

        return response;
    } catch (err) {
        console.log(err);
    }

}

function setHtml(item:any, img:any, altimg:any) {
    let addIva, addDa, classRkn1, icon, addUndVendidas, addBulto, mejorPrecio;
    if (item.iva > 0) addIva = `<span class="ml-1 text-sm bg-sky-400 text-white px-1 rounded font-semibold w-fit" title="'Porcentaje de IVA agregado'">+${item.iva}% | IVA</span>`;
    if (item.da > 0) addDa = `<span class="ml-1 text-sm bg-sky-500 text-white px-1 rounded font-semibold w-fit" title="'Porcentaje de descuento aplicado'">-${item.da}% | DA</span>`;

    if (item?.dataprod) {

        if (item?.dataprod?.mejor_precio_bool) {
            classRkn1 = 'flex font-semibold ml-1 text-sm items-center bg-green-600 text-white w-fit rounded px-1';
            icon = `<i class="bx bx-check-circle"></i>`;
        } else {
            classRkn1 = 'flex font-semibold ml-1 text-sm items-center bg-red-600 text-white w-fit rounded px-1';
            icon = `<i class="bx bx-error"></i>`;
        }

        mejorPrecio = `
        <p class='${classRkn1}'>
            ${icon}
            <span class='ml-1 text-xs'>
                ${item.dataprod?.mppBs?.toFixed(2)} - ${item.dataprod?.mpp?.toFixed(2)}
            </span>
        </p>
        `;

    } else {
        classRkn1 = 'flex font-semibold ml-1 text-sm items-center bg-green-600 text-white w-fit rounded px-1';
        icon = `<i class="bx bx-check-circle"></i>`;

        mejorPrecio = `
        <p class='${classRkn1}'>
            ${icon}
            <span class='ml-1 text-xs'>
                ${parseFloat(item?.precio1)} - ${(parseFloat(item?.precio1) /
                parseFloat(item?.prove_cfg?.FactorCambiario)).toFixed(2)}
            </span>
        </p>
        `;
    }

    if (item.und_vendidas > 0) {
        addUndVendidas = item.und_vendidas;
    } else {
        addUndVendidas = 0
    }

    if (item.tpmBulto > 0) {
        addBulto = item.tpmBulto;
    } else {
        addBulto = item.bulto;
    }


    const addFactor = `<span class="ml-1 text-sm"> - $ ${prices.getPrecioNeto(item, item?.prove_cfg?.FactorCambiario,true, true, true)}</span>`;

    const customHtml = `
        <div class="custom-swal-container">
            <img class="custom-swal-image" src="${img}" alt="${altimg}" onerror="this.src = '${altimg}'">
            <div class="modal-content w-full">
            <div class="column-left">
                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200">
                    <span class="text-sm font-medium text-slate-700">
                        DESCRIPCION:
                    </span>
                    <div class="flex" id="descuentos">
                        <p class="flex font-bold ml-1 text-sm items-center text-left">
                            <i class="bx bxs-capsule"></i>${item.desprod}
                        </p>
                        <div class="flex">
                            ${(addIva ? addIva : "")}
                            ${(addDa ? addDa : "")}
                        </div>
                    </div>
                </label>

                <label class="flex flex-col ml-1 flex-wrap content-start items-start" title='Precio de compra del producto'>
                    <span class="text-sm font-medium text-slate-700">
                        PRECIO:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bx-money"></i>
                        <span class="ml-1 text-sm">BS ${prices.getPrecioNeto(item, null, true, true, true)} </span>

                        ${(addFactor ? addFactor : "")}
                    </p>
                </label>
                <!-- MEJOR PRECIO -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200" title="El mejor precio de venta entre todas las droguerias" role='button'>
                    <span class="text-sm font-medium text-slate-700">
                        MEJOR PRECIO:
                    </span>
                    ${mejorPrecio}
                </label>

                <!-- CODIGO DE BARRA -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start">
                    <span class="text-sm font-medium text-slate-700">
                        CODIGO DE BARRA:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bx-barcode"></i>
                        <span class="ml-1">${item.barra}</span>
                    </p>
                </label>

                <!-- UNIDADES VENDIDAS -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200" title='Unidades vendidas desde ICOMPRAS' role='button'>
                    <span class="text-sm font-medium text-slate-700">
                        UNIDADES VENDIDAS:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bxs-package"></i>
                        <span class="ml-1">${addUndVendidas}</span>
                    </p>
                </label>

                <!-- MARCA -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start">
                    <span class="text-sm font-medium text-slate-700">
                        MARCA:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bx-purchase-tag"></i>
                        <span class="ml-1">${item.marca}</span>
                    </p>
                </label>

                <!-- UNIDAD DE BULTO -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200">
                    <span class="text-sm font-medium text-slate-700">
                        UNIDAD DEL BULTO:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bxs-cube"></i>
                        <span class="ml-1">${addBulto}</span>
                    </p>
                </label>
            </div>

            <div class="column-center">
                <!-- INVENTARIO -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start">
                    <span class="text-sm font-medium text-slate-700">
                        INVENTARIO:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bx-purchase-tag"></i>
                        <span class="ml-1">${item?.cantidad}</span>
                    </p>
                </label>
                <!-- CONSOLIDADO -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200">
                    <span class="text-sm font-medium text-slate-700">
                        CONSOLIDADO:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bxs-cube"></i>
                        <span class="ml-1">${(item?.dataprod?.invConsol ? item?.dataprod?.invConsol : 0)}</span>
                    </p>
                </label>

                <label class="flex flex-col ml-1 flex-wrap content-start items-start">
                    <span class="text-sm font-medium text-slate-700">
                        UNIDADES EN TRANSITO:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bx-car"></i>
                        <span class="ml-1">${item.transito}</span>
                    </p>
                </label>
                <!-- LOTE -->
                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200">
                    <span class="text-sm font-medium text-slate-700">
                        LOTE:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center">
                        <i class="bx bx-notepad"></i>
                        <span class="ml-1">${item.lote} | ${item.fecvence}</span>
                    </p>
                </label>

                <label class="flex flex-col ml-1 flex-wrap content-start items-start">
                    <span class="text-sm font-medium text-slate-700">
                        VENTA MEDIA DIARIA:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center"><i class='bx bxl-codepen'></i>
                        <span class="ml-1">${item.vmd}</span>
                    </p>
                </label>

                <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200">
                    <span class="text-sm font-medium text-slate-700">
                        MOLECULA:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center"><i class='bx bx-dna'></i>
                        <span class="ml-1">${item.molecula}</span>
                    </p>
                </label>

                <label class="flex flex-col ml-1 flex-wrap content-start items-start">
                    <span class="text-sm font-medium text-slate-700">
                        CODIGO:
                    </span>
                    <p class="flex font-semibold ml-1 text-sm items-center"><i class='bx bx-code'></i>
                        <span class="ml-1">${item.codprod}</span>
                    </p>
                </label>
            </div>

            <div class="column-right">
                <div class="flex flex-col">
                    <label class="flex flex-col ml-1 flex-wrap content-start items-start bg-gray-200" title='Cantidad sugerida de los productos a pedir'>
                        <span class="text-sm font-medium text-slate-700">
                            SUGERIDO SEGUN DIAS: ${Math.ceil(item?.dias)}
                        </span>
                        <div class="flex">
                            <div>
                                <p class="flex font-bold ml-1 text-sm items-center">
                                    <span class="text-sm">
                                        15 DIAS -> ${Math.ceil(item?.sug15)}</span>
                                </p>
                                <p class="flex font-bold ml-1 text-sm items-center">
                                    <span class="text-sm">
                                        30 DIAS -> ${Math.ceil(item?.sug30)}</span>
                                </p>
                                <p class="flex font-bold ml-1 text-sm items-center">
                                    <span class="text-sm">
                                        60 DIAS -> ${Math.ceil(item?.sug60)}</span>
                                </p>
                            </div>
                        </div>
                    </label>
                </div>

                <div class="flex flex-col">
                    <label class="flex flex-col ml-1 flex-wrap content-start items-start" title='Descuentos negociados'>
                        <span class="text-sm font-medium text-slate-700">
                            DESCUENTOS NEGOCIADOS:
                        </span>
                        <div class="flex">
                            <div>
                                <p class="flex font-bold ml-1 text-sm items-center" title='Descuento negociado por la marca'>
                                    <span class="text-sm">
                                        DN -> ${(item?.discountMarca > 0) ? parseFloat(item.discountMarca).toFixed(2) : 0}%</span>
                                </p>
                                <p class="flex font-bold ml-1 text-sm items-center" title='Descuento negociado por el producto'>
                                    <span class="text-sm">
                                        DP -> ${(item?.discountItem > 0) ? parseFloat(item.discountItem).toFixed(2) : 0}%</span>
                                </p>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        </div>

    `;

    return customHtml;
}

export default function openProductModal(axios:any, apiRoute:any, barra:any, img:any, altimg:any) {

    Swal.showLoading();

    getOne(axios, apiRoute, barra).then((response:any) => {
        const customHtml = setHtml(response.data, img, altimg);

        // code to show modal goes here
        Swal.fire({
            title: 'INFORMACION DEL PRODUCTO',
            html: customHtml,
            showCloseButton: true,
            showConfirmButton: false,
            focusConfirm: false,
            customClass: {
                popup: 'swal2-popup swal2-modal swal2-show swal-wide'
            }

        })
    })

}