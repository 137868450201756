<template>
    <div class="go-up-button">
        <button @click="$emit('click')" @keydown="handleKeyDown"
            class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded opacity-80">
            <i class='bx bxs-upvote'></i>
        </button>
    </div>
</template>

<script>
export default {
    name: 'AppGoUpButton',
    methods: {
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'ArrowUp') {
                this.$emit('click');
            }
        },
    },

};
</script>

<style scoped>
.go-up-button {
    position: fixed;
    bottom: 80px;
    right: 5px;

    padding: 10px;
}
</style>
