export function indexedDiscounts(content:any){
    let html = ``;
    if(content.desc_indexados){
        try{
            html += `<ul>`
            const descuentos = JSON.parse(content.desc_indexados);
            descuentos.map((item:any) => {
                html += `<li>${item.description}: ${item.amountWithoutPercent}%</li>`
            })

            html += `</ul>`
        }catch(err){
            console.log(err);
        }
    }

    html += `<hr><h1>Total: ${(100-content.total_indexado).toFixed(2)}%</h1>`;
    return html;
}

export function indexedDiscountsMarca(desc_indexados_marca:any){
    let html = ``;
    let sum = 0;
    if(desc_indexados_marca){
        try{
            html += `<ul>`
            desc_indexados_marca.map((item:any) => {
                html += `<li>${item.description}: ${item.amountWithoutPercent}%</li>`
                sum += parseFloat(item.amountWithoutPercent);
            })
            html += `</ul>`
        }catch(err){
            console.log(err);
        }
    }

    html += `<hr><h1>Total: ${(sum).toFixed(2)}%</h1>`;
    return html;
}