// store/email/index.js
import actions from "./actions";

const state = {
    emailSended: null as any
};


export default {
    namespaced: true,
    state: state,
    actions,
};

// Definir el tipo del estado
export type MailState = typeof state;
