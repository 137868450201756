import getPrecioNeto from "./getPrecioNeto";

export default function getPrecioMolecula(item:any) {
    let unidadmolecula = 1;

    if (item?.dataprod) {
        unidadmolecula = item.dataprod.unidadmolecula;
    }
    const precioBs = parseFloat(getPrecioNeto(item, undefined,
        true, true, true));
    const precioFactor = parseFloat(getPrecioNeto(item, item?.prove_cfg?.FactorCambiario,
        true, true, true));

    return [(precioBs / unidadmolecula).toFixed(2), (precioFactor / unidadmolecula).toFixed(2)];
}