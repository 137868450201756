<template>
	<div class="justify-between" :class="cardSize" ref="card">
		<div :class="index == 0
			? cardClass + ' rounded-t'
			: index == lastIndex
				? cardClass + ' rounded-b'
				: cardClass
			" class="p-2 flex flex-col leading-normal">
			<div class="flex" id="inside-card">
				<div class="flex flex-wrap justify-center one">
					<div id="img-box">
						<img :src="imageSrc" loading="lazy" class="img-responsive product-image rounded"
							@error="$event.target.src = altImageSrc" width="105" height="auto" oncontextmenu="return false" role="button"
							@click="$functions.modals.openProductModal($axios, $apiRoute, content.barra, imageSrc, altImageSrc)" />
					</div>
				</div>
				<div class="parent w-full">
					<div class="div1">
						<div class="flex flex-wrap justify-between">
							<div class="flex flex-wrap sm:flex-row">
								<cart-input :type="'text'" v-model="inputValue" :value="this.localContent.quantity"
									@add-to-cart="handleCart" text="Agregar al carrito" :content="localContent"
									@quality="updateValue"
									:icon="['bx', 'bxs-cart-add', animate ? 'bx-fade-right bx-flip-horizontal' : '']"
									:hasDiscount="this.localContent.discount"></cart-input>
								<label class="flex flex-col ml-1">
									<span class="text-xs font-medium text-slate-700">
										DESCRIPCION:
									</span>
									<div class="flex" id="descuentos">
										<p class="flex font-bold text-xs sm:text-sm items-center">
											<i class="bx bxs-capsule"></i>{{ title }} -
											{{ localContent.codprod }}
										</p>
									</div>
								</label>
								<label class="flex flex-col mx-4">
									<span class="text-xs font-medium text-slate-700">
										MOLECULA:
									</span>
									<div class="flex" id="molecula">
										<p class="flex font-bold text-xs sm:text-sm items-center">
											<i class='bx bx-dna'></i>
											{{ localContent.molecula }}
										</p>
									</div>
								</label>
								<label class="flex flex-col mx-4">
									<span class="text-xs font-medium text-slate-700">
										UNIDAD MOLECULA:
									</span>
									<div class="flex" id="precio_molecula">
										<p class="flex font-bold text-xs sm:text-sm items-center">
											<i class='bx bx-shape-triangle mr-1'></i>
											{{ (localContent?.dataprod?.unidadmolecula || 1) }}
										</p>
									</div>
								</label>
							</div>

							<div class="flex flex-wrap">

								<div class="flex">
									<span v-if="(100 - content.total_indexado) > 0 && content.desc_indexados"
										class="ml-1 text-md bg-sky-500 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center items-center py-0"
										v-tooltip="{
											content: $functions.tooltips.indexedDiscounts(localContent),
											html: true,
											position: 'left',
											autoHide: true,
											triggers: ['hover']
											}"
										role="button">DI | <i class='bx bx-tag'></i></span>
									<span v-if="localContent.iva > 0"
										class="ml-1 text-md bg-sky-400 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center py-0"
										v-tooltip="'Porcentaje de IVA agregado'">+{{
											localContent.iva }}% |
										IVA</span>
									<span v-if="localContent.discountMarca > 0"
										class="ml-1 text-md bg-sky-500 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center py-0"
										v-tooltip="'Descuento por marca'" role="button">+{{
											localContent.discountMarca }}% |
										DN</span>
									<span v-if="localContent.discountItem > 0"
										class="ml-1 text-md bg-sky-500 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center py-0"
										v-tooltip="'Descuento negociado por el producto'" role="button">+{{
											localContent.discountItem }}% |
										DP</span>

								</div>
							</div>
						</div>

					</div>
					<div class="flex flex-wrap">
						<ProductoInfo :title=localTitle :content=localContent :cardClass=localCardClass :cardSize=cardSize
							:headerClass=localHeaderClass :index=localIndex :lastIndex=lastIndex type="2" :user="user"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import ProductoInfo from './cardContent/producto/ProductoInfo.vue';
import { mapState, mapActions } from 'vuex';

export default {
	name: "CardCatalogo",
	props: [
		"title",
		"content",
		"cardClass",
		"cardSize",
		"headerClass",
		"index",
		"lastIndex",
		'user'
	],
	components: {
		ProductoInfo
	},
	data() {

		return {
			animate: false,
			imageSrc: ``,
			altImageSrc: ``,
			showModal: false,
			inputValue: '',
			localCardClass: this.cardClass ?? '',
			localTitle: this.title ?? '',
			localIndex: this.index ?? 0,
			localHeaderClass: this.headerClass ?? '',
			localContent: { ...this.content },
		};
	},
	computed: {
		...mapState("orders", ["cartItems", "cartItemsQuantity"]),
	},
	methods: {
		...mapActions("orders", ["addToCart", "fetchCartItemsQuantity"]),
		focusOnInput() {
			const inputElement = this.$el.querySelector('input');
			if (inputElement) {
				setTimeout(() => {
					inputElement.focus();
				}, 300);
			}
		},
		toggleAnimation() {
			this.animate = true;
			setTimeout(() => {
				this.animate = false;
			}, 1500);
		},
		showSweetAlert(obj) {
			Swal.fire(obj);
		},
		closeModal() {
			this.showModal = false;
		},
		updateValue(item) {
			this.inputValue = item;
		},
		handleCart(product) {
			// Get the input value (quantity)
			this.toggleAnimation();
			const quantity = parseFloat(this.inputValue);
			// Check if the input value is less than or equal to 0
			if (isNaN(quantity) || quantity <= 0) {
				// Show an alert to the user
				let obj = {
					title: 'Ups!!!',
					text: 'La cantidad ingresada debe ser mayor a 0',
					icon: 'error',
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'custom-confirm-button', // Apply the custom class to the confirm button
					},
				}
				this.showSweetAlert(obj)
				return; // Exit the function to prevent adding the product to the cart
			}

			// Dispatch the addToCart action to add the product to the cart in the Vuex store
			const productToAdd = {
				...product,
				quantity,
			};

			this.addToCart({
				axios: this.$axios,
				apiRoute: this.$apiRoute,
				router: this.$router,
				data: productToAdd
			}).then(() => {
				// After adding the product to the cart, fetch the updated cart items quantity
				this.fetchCartItemsQuantity({
					axios: this.$axios,
					apiRoute: this.$apiRoute,
				}).then(() => {
					// Once the cart items quantity is updated, commit a mutation to update it in the state
					this.$store.commit('orders/updateCartItemsQuantity', this.cartItemsQuantity);
				});
			});
		},
		tooltipHtml(content){
			let html = ``;
			if(content.desc_indexados){
				try{
					html += `<ul>`
					let descuentos = JSON.parse(content.desc_indexados);
					descuentos.map((item) => {
						html += `<li>${item.description}: ${item.amountWithoutPercent}%</li>`
					})

					html += `</ul>`
				}catch(err){
					console.log(err);
				}
			}

			html += `<hr><h1>Total: ${content.total_indexado}%</h1>`;
			return html;
		}
	},
	watch: {
		"content.barra": function () {
			this.imageSrc = `${this.$imgProdUrl}/${this.content.barra}.jpg`;
			this.altImageSrc = `${this.$imgProdUrl}/noimagen.jpg`;
		},

		"content": function () {
			this.localContent = {
				...this.content
			}
		},

		showModal(newVal) {
			console.log("showModal changed:", newVal);
		},
	},
	mounted() {
		// Find the corresponding cart item and update the quantity in the local copy

		(this.imageSrc = `${this.$imgProdUrl}/${this.content.barra}.jpg`),
			(this.altImageSrc = `${this.$imgProdUrl}/noimagen.jpg`);
	},
};
</script>

<style>
.product-image {
	transition: transform 0.3s;
}

.product-image:hover {
	transform: scale(1.2);
	z-index: 99;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.6);
	position: relative;
}
</style>

