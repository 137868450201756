import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const selectedFilters = {
        checkOptionsMarca: true as boolean,
        checkOptionsMolecula: true as boolean,
        checkboxStatesMarca: [] as any,
        checkboxStatesMolecula: [] as any
}

const selectedRange = {
    rangoDesde: 50 as number,
    rangoHasta: 50 as number,
    selectFrom: false as boolean,
    selectTo: false as boolean,
}

const state = {
    catalogodata: [] as any,
    originalCatalogoData: [] as any,
    next_page_url: null as string|null,
    last_page_url: null as string|null,
    prev_page_url: null as string|null,
    first_page_url: null as string|null,
    last_page: "" as string|null|number,
    current_page: "" as string|null|number,
    loading: true as boolean,
    isLoadingMore: false as boolean,
    total: null as number|null,
    searchParams: {} as any,
    errorMessage: null as string|null,
    showing: null as number|null,
    from: 1 as number|null,
    to: 50 as number|null,
    selectedFilters,
    selectedRange
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};

// Definir el tipo del estado
export type CatalogState = typeof state;

export type CatalogSelectedFilters = typeof state.selectedFilters;

export type CatalogSelectedRange = typeof state.selectedRange;
