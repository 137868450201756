<template>
    <div class="justify-between" :class="cardSize" ref="card">
        <div class="font-bold text-xl justify-between flex p-2 rounded-t"
            :class="headerClass">{{ title }}
            <span><i :class='icon'></i></span>
        </div>
        <div :class="cardClass" class="rounded-b p-4 block leading-normal ">
            <div class="mb-2">
                <div class="flex justify-center" ref="type3">
                    <LineChart :options="{ responsive: true, maintainAspectRatio: false }" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from '../charts/LineChart.vue';

export default {
    name: 'DasboardCards',
    props: ['title', 'content', 'cardClass', 'icon', 'cardSize', 'headerClass'],
    components: {
        LineChart
    },
    data() {
        return {}
    },
    mounted() {

    }
}
</script>

<style></style>
