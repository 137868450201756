// In your auth module
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
  authenticated: false,
  user: "" as any,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

// Definir el tipo del estado
export type AuthState = typeof state;
