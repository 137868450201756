<template>
  <header class="sticky top-0 z-50" v-if="getAuth">
    <nav>
      <app-navbar :options="options" :hoptions="items" />
    </nav>
  </header>
  <aside v-if="getAuth">
    <app-sidebar :items="items" />
  </aside>

  <RouterView />

  <footer class="text-white p-3 flex justify-between bg-color-footer h-footer">
    <p class="flex flex-col footer-text">Copyright © 2013-2023 <span class="text-xs footer-text">ISB SISTEMAS, C.A.</span>
    </p>
    <p class="footer-text" @click="openVersionModal" role="button">Version: 1.1.0</p>
  </footer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "App",
  computed: {
    ...mapState("orders", ["cartItems", "cartItemsQuantity"]),
    ...mapGetters("auth", ["IS_USER_AUTHENTICATED"]),

    getAuth() {
      return this.IS_USER_AUTHENTICATED
    }
  },
  mounted() {
    this.updateData();

    if (!this.user?.token) {
      this.$store.commit(`auth/SET_AUTHENTICATION`, false);
      this.$store.commit(`auth/SET_USER`, null);
      this.$router.push('/');
    }

    if (this.$route.path === '/' && this.user) {
      this.$router.push('/inicio');
    }

    // Ejecuta tokenValidation cada minuto (60,000 milisegundos)
    this.intervalId = setInterval(() => {
      this.tokenValidation().then((status) => {
        switch (status) {
          case 401:
            this.$store.commit(`auth/SET_AUTHENTICATION`, false);
            this.$router.push('/');
            break;
        }
      });
    }, 60000); // Intervalo de 1 minuto
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // Limpia el intervalo al desmontar el componente
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("http", ["updateStatus"]),
    ...mapActions('orders', ['fetchCartItemsQuantity']),
    updateData() {
      this.fetchCartItemsQuantity({
        axios: this.$axios,
        apiRoute: this.$apiRoute
      }).then((res) => {
        this.$store.commit("orders/updateCartItemsQuantity", res.items); // Use the mutation to update cartItems
      }).catch((err) => console.log(err));

    },
    async tokenValidation() {

      let token = localStorage.getItem("authToken");
      return this.$axios
        .post(`${this.$apiRoute}/auth/me`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.$store.commit(`auth/SET_USER`, JSON.stringify(response.data));
          return (response.status);
        })
        .catch((error) => {
          if (this.$route.path !== '/') {
            this.$router.push('/');
          }
          return (error.response.status);
        });
    },

    openVersionModal() {

      let version = `
      <h3 class="font-semibold text-gray-900">Versión 1.2.0 (Fecha: 14-12-2023)</h3>
      `;
      let novedades = `
      <p class="text-left">- Sin novedades aplicadas.</p>
      `;

      let mejoras = `
      <p class="text-left">- Se modificó el descuento por marca para que este sea indexado</p>
      `;

      let correcciones = `
      <p class="text-left">- Sin correcciones aplicadas</p>
      `;

      Swal.fire({
        title: '<h2 class="text-lg font-bold text-blue-500">Registro de Versiones</h2>',
        html: `
        <div class="text-sm text-gray-700">
          ${version}
          <h4 class="font-semibold text-blue-500 mt-2">Novedades</h4>
          ${novedades}
          <h4 class="font-semibold text-blue-500 mt-2">Mejoras</h4>
          ${mejoras}
          <h4 class="font-semibold text-blue-500 mt-2">Correcciones</h4>
          ${correcciones}
        </div>
        `,
        confirmButtonText: 'Cerrar',
        customClass: {
          title: 'text-2xl',
          content: 'text-base',
          confirmButton: 'custom-confirm-button'
        }
      });

    }
  },
  data() {

    return {
      intervalId: null,
      user: this.$functions.auth.ProcessUserData(),
      options: [
        {
          link: "#",
          name: "Perfil",
          icon: "bx bx-user-circle",
        },
        {
          link: "#",
          name: "Notificaciones",
          icon: "bx bxs-bell",
        },
        {
          link: "#",
          name: "Cerrar Sesión",
          icon: "bx bx-log-out",
        },
      ],
      items: [
        {
          link: "inicio",
          name: "Inicio",
          icon: "bx bx-home-alt",
        },
        {
          link: "catalogo",
          name: "Catalogo",
          icon: "bx bx-list-ul",
        },
        {
          link: "vendedores",
          name: "Vendedores",
          icon: "bx bxs-user-account",
        },
        {
          link: "lista-pedidos",
          name: "Pedidos",
          icon: "bx bx-cart-alt",
        },
        // {
        //   link: "#",
        //   name: "Inventario",
        //   icon: "bx bxs-package",
        // },
        // {
        //   link: "#",
        //   name: "Proveedores",
        //   icon: "bx bxs-user",
        // },
        // {
        //   link: "#",
        //   name: "Reportes",
        //   icon: "bx bx-copy-alt",
        // },
        // {
        //   link: "#",
        //   name: "Caracteristicas",
        //   icon: "bx bx-tag-alt",
        // },
        // {
        //   link: "#",
        //   name: "Configuracion",
        //   icon: "bx bx-cog",
        // },
      ],
    };
  },

};
</script>
