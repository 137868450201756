import { Commit } from "vuex";
import { CatalogState, CatalogSelectedFilters, CatalogSelectedRange } from ".";
import { Axios } from "axios";


export default {
    setSelectedFilters({ commit }: {commit: Commit}, selectedFilters:CatalogSelectedFilters) {
        commit('SET_SELECTED_FILTERS', selectedFilters);
    },
    setSelectedRange({ commit }: {commit:Commit}, selectedRange:CatalogSelectedRange) {
        commit('SET_SELECTED_RANGE', selectedRange);
    },

    async getCatalogo(
        { commit, state }: {commit:Commit, state:CatalogState},
        { axios, apiRoute, url = null, searchParams = null } : {axios:Axios, apiRoute:string, url:string|null, searchParams:any}
    ) {
        const token = localStorage.getItem("authToken");
        commit("setLoading", true);

        if (url == null) {
            url = `${apiRoute}/cat/show`;
            commit("setLoading", true);
        }

        try {
            const response = await axios.post(url, { ...searchParams }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });

            commit("setNextPageUrl", response.data.next_page_url);
            commit("setPrevPageUrl", response.data.prev_page_url);

            commit("setFirstPageUrl", response.data.first_page_url);
            commit("setLastPageUrl", response.data.last_page_url);
            commit("setLastPage", response.data.last_page);
            commit("setCurrentPage", response.data.current_page);
            commit("setTotal", response.data.total);
            commit("setShowing", response.data.to);
            commit("setFrom", response.data.from);
            commit("setCatalogoData", state.catalogodata = response.data.data);

        } catch (err) {
            // Handle error
            commit(
                "setErrorMessage",
                "An error occurred while fetching data. Please try again."
            );

            console.error(err);
        } finally {
            commit("setLoading", false);
            commit("setIsLoadingMore", false);
        }
    },
};
