import { CatalogState, CatalogSelectedFilters, CatalogSelectedRange } from ".";

export default {
    setCatalogoData(state:CatalogState, data:any) {
        state.catalogodata = data;
    },
    setNextPageUrl(state:CatalogState, url:string) {
        state.next_page_url = url;
    },
    setPrevPageUrl(state:CatalogState, url:string) {
        state.prev_page_url = url;
    },
    setFirstPageUrl(state:CatalogState, url:string) {
        state.first_page_url = url;
    },
    setLastPageUrl(state:CatalogState, url:string) {
        state.last_page_url = url;
    },
    setLastPage(state:CatalogState, page:number) {
        state.last_page = page;
    },
    setCurrentPage(state:CatalogState, page:number) {
        state.current_page = page;
    },
    setLoading(state:CatalogState, loading:boolean) {
        state.loading = loading;
    },
    setIsLoadingMore(state:CatalogState, loading:boolean) {
        state.isLoadingMore = loading;
    },
    setSearchParams(state:CatalogState, searchParams:any) {
        state.searchParams = searchParams;
    },
    setErrorMessage(state:CatalogState, message:any) {
        state.errorMessage = message;
    },
    setTotal(state:CatalogState, total:number) {
        state.total = total;
    },
    setShowing(state:CatalogState, showing:number) {
        state.showing = showing;
    },
    setFrom(state:CatalogState, from:number) {
        state.from = from;
    },
    setTo(state:CatalogState, to:number) {
        state.to = to;
    },
    SET_SELECTED_FILTERS(state:CatalogState, selectedFilters:CatalogSelectedFilters) {
        state.selectedFilters = selectedFilters;
    },
    SET_SELECTED_RANGE(state:CatalogState, selectedRange: CatalogSelectedRange) {
        state.selectedRange = selectedRange;
    },

};
