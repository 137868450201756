import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import { PedidoSelectedFilters, PedidosData } from "@/interfaces/Pedidos";

export type PedidosState = typeof state;

export type PedidosFilters = typeof selectedFilters;

const selectedFilters: PedidoSelectedFilters = {
    checkOptionsMarca: true,
    checkboxStatesMarca: [],
    selectedEstado: '',
    fechaInicial: null,
    fechaFinal: null,
}

const state = {
    pedidosdata: [] as PedidosData,
    originalPedidosData: [] as PedidosData,
    next_page_url: null as string | null,
    last_page: "" as string | null | number,
    current_page: "" as string | null | number,
    loading: true as boolean,
    isLoadingMore: false as boolean,
    searchParams: {} as any,
    errorMessage: null,
    last_page_url: null as string | null,
    prev_page_url: null as string | null,
    first_page_url: null as string | null,
    from: 1 as number,
    to: 50 as number,
    total: null as null | number,
    selectedFilters,
    showing: null as string | null | number,
    buttonLoading: false as boolean,

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};

