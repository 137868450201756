// store/index.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './auth';
import http from './http';
import catalog from './catalog';
import catalogConfig, { CatalogConfigState} from './catalog/config';
import pedidos from './pedidos';
import orders from './orders';
import email from './email'; // Import the 'email' Vuex module from './email.js'

const store = createStore({
  modules: {
    auth,
    http,
    catalog,
    orders,
    pedidos,
    email, // Register the 'email' module
    catalogConfig
  },
  plugins: [
    createPersistedState({
      paths: ['auth'] // Specify the modules to persist their state
    })
  ]
});

export default store;