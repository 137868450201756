<template>
    <div class="flex">
        <div class="relative flex flex-wrap items-stretch w-24 mr-1">
            <input :type="type" v-model="localValue" @keyup.enter="addToCartHandler"
                v-tooltip="{
                    content: `
                <p class='text-center'>La cantidad a pedir alcanzará</p>
                <p class='text-center'>para aproximadamente:</p>
                <p class='text-center'>${Math.ceil(localValue / parseFloat(localVmd))} días</p>`, html: true
                }"
                class="relative m-0 rounded-l block w-[1px] min-w-0 flex-auto border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary" />


            <button @click="addToCartHandler"
                class="z-[2] h-auto inline-block rounded-r px-3 pb-0 pt-1 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-600 focus:z-[3] focus:bg-primary-600 focus:outline-none focus:ring-0 active:bg-primary-700"
                :class="localContent.inCart && icon !== 'bx bx-edit-alt' ? 'bg-green-500' : 'bg-color-barrasuperior'"
                data-te-ripple-init type="button" id="button-addon2">
                <i :class="icon" v-tooltip="{ content: text, triggers: ['hover'] }">
                </i>
            </button>

        </div>

        <div class="relative flex flex-wrap items-stretch w-28" v-if="localhasDiscount">
            <input :type="type" v-model="localValueDiscount" @keyup.enter="applyDiscountHandler"
                class="relative m-0 rounded-l block w-[1px] min-w-0 flex-auto border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary" />


            <button @click="applyDiscountHandler"
                class="h-auto inline-block rounded-r px-3 pb-0 pt-1 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-600 focus:z-[3] focus:bg-primary-600 focus:outline-none focus:ring-0 active:bg-primary-700"
                :class="localContent.inCart && icon !== 'bx bx-edit-alt' ? 'bg-green-500' : 'bg-color-barrasuperior'"
                data-te-ripple-init type="button" id="button-addon2">
                <i :class="'bx bxs-discount'"
                    v-tooltip="{ content: 'Aplicar descuento al producto', triggers: ['hover'] }"></i>
            </button>
        </div>

        <div class="relative flex flex-wrap items-stretch" v-if="localhasInedexesDiscount"
            v-tooltip="{ content: 'Agregar descuentos indexados', triggers: ['hover'] }">
            <button @click="openModal"
                class="mx-2 px-2 text-white bg-color-barrasuperior rounded hover:bg-primary-600 flex flex-wrap items-center">
                <i class='bx bx-layer-plus text-lg '></i>
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex"; // Import mapMutations from vuex

export default {
    name: "CartInput",
    props: ["type", "placeholder", "label", "inputClass", "text", "content", "icon", 'animate', 'value', 'value2', 'hasDiscount', 'hasInedexesDiscount', 'CampoVmdInv'],
    data() {
        const campo_vmd = this.CampoVmdInv;
        return {
            localValue: this.value ?? this.content.quantity,
            localVmd: this.content[campo_vmd],
            localValueDiscount: this.value2 ?? null,
            localContent: this.content,
            localhasDiscount: this.hasDiscount,
            localhasInedexesDiscount: this.hasInedexesDiscount ?? false
        };
    },
    watch: {
        value(newValue) {
            // Watch for changes in the value prop and update inputValue accordingly
            this.localValue = newValue;
        },
        value2(newValue) {
            if(newValue) this.localValueDiscount = parseFloat(newValue).toFixed(2);
            else this.localValueDiscount = null;
        }
    },
    methods: {
        ...mapMutations("orders", ["addToCart"]), // Map the ADD_TO_CART mutation from the "orders" module

        addToCartHandler() {
            // Logic to obtain the data of the product to add to the cart
            this.localContent.inCart = true;
            this.$emit("quality", this.localValue);
            this.$emit("add-to-cart", this.content);
            // Emit a custom event to notify that a product was added to the cart
        },
        applyDiscountHandler() {
            this.$emit("discount", this.localValueDiscount);
        },

        openModal() {
            // Emitir un evento personalizado con los datos que desees enviar al componente padre
            this.$emit('open-modal')
        }
    },
};
</script>
