import Swal from 'sweetalert2';

export default function openMejorPrecioModal(barra: any, axios: any, apiRoute: any) {

    Swal.fire({
        allowOutsideClick: false,
        focusConfirm: false,
        didOpen: () => {
            Swal.showLoading();
            const token = localStorage.getItem(`authToken`);

            axios.get(`${apiRoute}/cat/getBestSeller`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: { barra }
            })
                .then((response: any) => {
                    // La petición fue exitosa, muestra el contenido de la respuesta
                    const data = response.data;

                    const html = data.map((item: any) =>
                    `<tr class="bg-white dark:bg-gray-800">
                        <td class="px-4 py-3 text-sm text-left w-[150px]">${item.DESCRIPCION}</td>
                        <td class="px-4 py-3 text-sm text-left  w-[100px]">${item.CODPROV}</td>
                        <td class="px-4 py-3 text-sm text-center  w-[100px]">${item.CODPROD}</td>
                        <td class="px-4 py-3 text-sm text-left w-[200px] break-words">${item.DESPROD}</td>
                        <td class="px-4 py-3 text-sm text-center  w-[100px]">${item.CANTIDAD}</td>
                        <td class="px-4 py-3 text-sm text-right  w-[100px]">${item.NETO}</td>
                    </tr>`
                    ).join("");

                    Swal.fire({
                        title: 'COMPARATIVO MEJOR PRECIO DE VENTA',
                        html: `
                        <div class="w-full overflow-hidden rounded-lg shadow-xs">
                            <div class="w-full overflow-x-auto">
                                <table class="w-full whitespace-no-wrap">
                                    <thead>
                                        <tr class="text-xs font-semibold tracking-wide text-center text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                            <th class="px-4 py-3 w-[150px]">PROVEEDOR</th>
                                            <th class="px-4 py-3 w-[100px]">COD. PROVEEDOR</th>
                                            <th class="px-4 py-3 w-[100px]">COD. PRODUCTO</th>
                                            <th class="px-4 py-3 w-[200px]">DESCRIPCION PRODUCTO</th>
                                            <th class="px-4 py-3 w-[100px]">CANTIDAD</th>
                                            <th class="px-4 py-3 w-[100px]">NETO</th>
                                        </tr>
                                    </thead>

                                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                                        ${html}
                                    </tbody>
                                </table>
                            </div>
                        </div>`,
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup swal2-modal swal2-show swal-wide'
                        }
                    });



                })
                .catch((error: any) => {
                    // La petición falló, muestra detalles del error si disponibles
                    const errorMessage = error.response ? error.response.data : 'La petición falló.';
                    Swal.fire({
                        focusConfirm: false,
                        title: 'Error',
                        text: errorMessage,
                        icon: 'error',
                        showConfirmButton: false
                    });
                });
        }
    })

}
