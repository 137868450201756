import Swal from 'sweetalert2';

export default function openInfoPedidosModal() {
    Swal.fire({
        focusConfirm: false,
        title: 'Atajos de teclado',
        html: `
            <div class="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-4">
                <div class="shortcut">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Derecha</div>
                    <div>Ir a la siguiente página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Alt</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Arriba</div>
                    <div>Ir al principio de la página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Izquierda</div>
                    <div>Ir a la anterior página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Alt</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Abajo</div>
                    <div>Ir al final de la página</div>
                </div>
            </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
            popup: 'swal-popup-custom swal-wide',
        },
    });
}
