// store/orders/index.js

import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
    currentOrder: null as number|null, // Lista de órdenes de pedidos
    cartItems: [] as any, // Lista de productos en el carrito
    cartItemsQuantity: 0 as number, // Cantidad de productos
    total_general: 0 as number|string,
    total_general_mone: 0 as number|string,
    total_general_ext: 0 as number|string,
    total_general_mone_ext: 0 as number|string,
    desc_indexados_marca: {} as any,
    total_unidades: 0 as number|string,
    total_renglones: 0 as number|string,
    marcas: [] as any
};

export default {
    namespaced: true,
    state,
    getters,
    mutations, // Agrega los mutations importados aquí
    actions, // Agrega los actions importados aquí
};

export const RESET_CART_ITEMS = "RESET_CART_ITEMS";

// Definir el tipo del estado
export type OrdersState = typeof state;