<template>
    <div class="flex items-left flex-col justify-end" id="select">
        <div class="mr-2 font-bold text-white">{{ label }}</div>
        <select
            class="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
            v-model="selectedOption" v-if="type == 'object' && datasource == 'cliente'" @change="onSelectChange">
            <option v-for="option in options" :key="option" :value="option.codcli">{{ option.codcli }} - {{ option.nomcli }}
            </option>
        </select>
        <select
            class="py-3 px-4 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
            v-model="selectedOption" @change="onSelectChange" v-if="type == 'string'">
            <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
        </select>
    </div>
</template>
  
<script>

export default {
    name: "AppSelect",
    props: ['options', 'label', 'type', 'datasource', 'user_data'],
    data() {
        return {
            selectedOption: "",
        };
    },
    mounted() {
        if (this.datasource == 'cliente') {
            let user = JSON.parse(this.user_data)
            this.selectedOption = user.detalles.filter(item => item.codcli == user.ultcodcli)[0].codcli;
        } else {
            if (this.options.length > 1) {
                this.selectedOption = this.options[0];
            }
        }
    },
    methods: {
        onSelectChange(event) {
            let token = localStorage.getItem(`authToken`)

            let data = {
                "codcli": event.target.value
            }
            let fd = this.$functions.fd.parse(data);

            this.$axios.put(`${this.$apiRoute}/user/update`, fd, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    // Handle successful logout

                    console.log(response);

                })
                .catch(error => {
                    // Handle logout error
                    console.log(error);
                });
            
        },
    }
};
</script>
