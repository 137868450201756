// store/orders/mutations.js
import { Axios } from "axios";
import { OrdersState } from ".";
import functions from "@/functions";

const RESET_CART_ITEMS = "RESET_CART_ITEMS";

const mutations = {
    updateCartItems(state: OrdersState, cartItems: any) {
        state.total_general_mone = cartItems.total_general_mone;
        state.total_general_ext = cartItems.total_general_ext;
        state.total_general_mone_ext = cartItems.total_general_mone_ext;
        state.total_unidades = cartItems.total_unidades;
        state.total_renglones = cartItems.total_renglones;
        state.desc_indexados_marca = cartItems.desc_indexados_marca;
        state.marcas = cartItems.marcas;
        state.total_general = cartItems.total_general;
        state.cartItems = cartItems;
    },
    updateCartItemsQuantity(state: OrdersState, quantity: number) {
        state.cartItemsQuantity = quantity;
    },
    addToCart(state: OrdersState, product: any) {
        // Check if the item already exists in the cart, considering case sensitivity
        try {
            const existingCartItem = state.cartItems.items.find(
                (item: any) => item.codprod === product.codprod
            );
            if (!existingCartItem) {
                // If the item doesn't exist in the cart, add it as a new item
                state.cartItemsQuantity += 1; // Increase the cartItemsQuantity
            } else {
                // If the item exists, update the quantity
                state.cartItemsQuantity -= 1; // Adjust the cartItemsQuantity
            }
        } catch (error) {
            state.cartItemsQuantity += 1; // Increase the cartItemsQuantity
        }



    },

    addAllToCart(state: OrdersState, items: any) {

        const existingItems = [];

        for (const newItem of items) {
            const existingItem = state.cartItems.items.find(
                (cartItem: any) => cartItem.codprod === newItem.codprod
            );

            if (!existingItem) {
                existingItems.push(newItem)
            } else {
                existingItem.quantity = newItem.quantity
                existingItem.discountItem = newItem.discountItem
                existingItem.discountMarca = newItem.discountMarca

                existingItems.push(existingItem)
            }

        }

        state.cartItems.items = [...existingItems];
    },

    removeOneFromCart(state: OrdersState, barra: any) {
        // Find the cart item to remove
        const cartItemToRemove = state.cartItems.items.find(
            (item: any) => item.barra === barra
        );

        if (cartItemToRemove) {
            state.cartItemsQuantity -= 1; // Decrease the cartItemsQuantity
        }

        // Remove the found cart items from the state.orders array
        state.cartItems.items = state.cartItems.items.filter((product: any) => product.barra !== barra);
    },
    // Mutation to remove a product from the cart and set its 'inCart' property to false
    removeFromCart(state: OrdersState, marca: any) {
        const cartItemsToRemove = state.cartItems.items.filter(
            (item: any) => item.marca === marca
        );

        cartItemsToRemove.forEach(() => {
            state.cartItemsQuantity -= 1; // Decrease the cartItemsQuantity for each removed item
        });

        // Remove the found cart items from the state.orders array
        state.cartItems.items = state.cartItems.items.filter((product: any) => product.marca !== marca);
    },

    // Mutation to update the discount of a specific item in cartItems
    setItemDiscountMarca(state: OrdersState, { cartItemsToApplyDiscount, marca, discount }: { cartItemsToApplyDiscount: any, marca: string, discount: number }) {
        // Use filter to find items with matching 'marca'
        const matchingItems = cartItemsToApplyDiscount.filter(
            (item: any) => item.marca === marca
        );

        // Update the state with the discount for the matching items
        state.cartItems.items = state.cartItems.items.map((item: any) => {
            if (matchingItems.includes(item)) {
                return {
                    ...item,
                    discountMarca: discount,
                };
            } else {
                return item;
            }
        });
    },


    updateItemQuantity(state: OrdersState, { codprod, quantity }: { codprod: string, quantity: number }) {
        // Find the index of the cart item with the matching codprod (product code)
        const index = state.cartItems.items.findIndex((item: any) => item.codprod === codprod);
        if (index !== -1) {
            // Update the quantity of the cart item
            state.cartItems.items[index].quantity = quantity;
        }
    },

    updateItemDiscount(state: OrdersState, { codprod, discount }: { codprod: string, discount: number }) {
        // Find the index of the cart item with the matching codprod (product code)

        const index = state.cartItems.items.findIndex((item: any) => item.codprod === codprod);
        if (index !== -1) {
            // Update the quantity of the cart item
            state.cartItems.items[index].discountItem = discount;
        }
    },

    [RESET_CART_ITEMS](state: OrdersState) {
        state.cartItems = [];
    },

    updateIndexedDiscount(state: OrdersState, { codprod, discount, jsonDiscounts }: { codprod: string, discount: number, jsonDiscounts: string }) {
        const index = state.cartItems.items.findIndex((item: any) => item.codprod === codprod);
        if (index !== -1) {
            // Update the quantity of the cart item
            state.cartItems.items[index].total_indexado = discount;
            state.cartItems.items[index].desc_indexados = jsonDiscounts;
        }
    },

    updateIndexedDiscountMarca(state: OrdersState, { marca, discount, jsonDiscounts }: { marca: string, discount: any, jsonDiscounts: string }) {
        const filteredItems = state.cartItems.items.filter((item: any) => item.marca === marca);

        // Update discounts for the filtered items

        filteredItems.forEach((item: any) => {
            item.discountMarca = parseFloat(discount).toFixed(2);
            item.desc_indexados_marca = jsonDiscounts;
        });

    }
};

export default mutations;
