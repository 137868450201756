import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
    status: null as null|number,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};


// Definir el tipo del estado
export type HttpState = typeof state;
