import { CatalogConfigState, CatalogConfigSelectedFilters } from ".";

export default {
    setCatalogoConfigData(state: CatalogConfigState, data:any) {
        state.catalogoconfigdata = data;
    },
    setLoading(state: CatalogConfigState, loading:boolean) {
        state.loading = loading;
    },
    setSearchParams(state: CatalogConfigState, searchParams:any) {
        state.searchParams = searchParams;
    },
    setErrorMessage(state: CatalogConfigState, message:any) {
        state.errorMessage = message;
    },
    setTotal(state: CatalogConfigState, total:number) {
        state.total = total;
    },
    SET_SELECTED_FILTERS(state: CatalogConfigState, selectedFilters:CatalogConfigSelectedFilters) {
        state.selectedFilters = selectedFilters;
    },
    setNextPageUrl(state:CatalogConfigState, url:string) {
        state.next_page_url = url;
    },
    setPrevPageUrl(state:CatalogConfigState, url:string) {
        state.prev_page_url = url;
    },
    setFirstPageUrl(state:CatalogConfigState, url:string) {
        state.first_page_url = url;
    },
    setLastPageUrl(state:CatalogConfigState, url:string) {
        state.last_page_url = url;
    },
    setLastPage(state:CatalogConfigState, page:number) {
        state.last_page = page;
    },
    setCurrentPage(state:CatalogConfigState, page:number) {
        state.current_page = page;
    },
    setIsLoadingMore(state:CatalogConfigState, loading:boolean) {
        state.isLoadingMore = loading;
    },
    setShowing(state:CatalogConfigState, showing:number) {
        state.showing = showing;
    },
    setFrom(state:CatalogConfigState, from:number) {
        state.from = from;
    },
    setTo(state:CatalogConfigState, to:number) {
        state.to = to;
    },
};
