import { Commit } from "vuex";
import { PedidosFilters, PedidosState } from ".";
import { Axios } from "axios";
export default {
    setSelectedFilters({ commit }: { commit: Commit }, selectedFilters: PedidosFilters) {
        commit('SET_SELECTED_FILTERS', selectedFilters);
    },
    async getPedidos(
        { commit, state }: { commit: Commit, state: PedidosState },
        { axios, apiRoute, url = null, searchParams = {}, showPedidos = false } : {axios:Axios, apiRoute: string, url: string|null, searchParams:any, showPedidos:boolean}
    ) {
        const token = localStorage.getItem("authToken");
        commit("setLoading", true);

        if (url == null) {
            url = `${apiRoute}/pedido/show`;
            commit("setLoading", true);
        }

        if (showPedidos === true) {
            searchParams.showPedidos = true;
        }
        try {
            const response = await axios.post(url, searchParams, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            commit("setNextPageUrl", response.data.next_page_url);
            commit("setPrevPageUrl", response.data.prev_page_url);

            commit("setFirstPageUrl", response.data.first_page_url);
            commit("setLastPageUrl", response.data.last_page_url);
            commit("setLastPage", response.data.last_page);
            commit("setCurrentPage", response.data.current_page);
            commit("setTotal", response.data.total);
            commit("setShowing", response.data.to);
            commit("setFrom", response.data.from);

            commit("setPedidoData", state.pedidosdata = response.data.data);
        } catch (err) {
            // Handle error
            commit(
                "setErrorMessage",
                "An error occurred while fetching data. Please try again."
            );

            console.error(err);
        } finally {
            commit("setLoading", false);
            commit("setIsLoadingMore", false);
        }
    },
    async deletePedido({ commit }:{commit:Commit}, { id, axios, apiRoute }:{id:any, axios:Axios, apiRoute:string}) {
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const data = {
            id
        };

        await axios.delete(apiRoute + '/pedido/destroyOne', {
            data: data, // The data you want to send in the request body
            headers: headers, // Optional headers
        });

        commit("deletePedido", id);

    },
    async downloadPedido({ commit }:{commit:Commit}, {item, axios, apiRoute, searchParams = {}}:{item:any, axios:Axios, apiRoute:string, searchParams:any}){
        const token = localStorage.getItem("authToken");
        commit("setButtonLoading", true);
        const urlPath = apiRoute+'/pedido/downloadOptions';
        searchParams.id = item.id;
        searchParams.marca = item.marca;

        try {
            const response = await axios.post(urlPath, searchParams, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob', // Set the response type to 'blob'

            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            const filename = `PEDIDO_${item.marca}_${item.id}_${Date.now().toString()}`;

            if(searchParams.type == 'pdf'){
                link.setAttribute('download', filename + '.pdf'); // Set the filename
            }

            if(searchParams.type == 'excel'){
                link.setAttribute('download', filename + '.xlsx'); // Set the filename
            }

            if(searchParams.type == 'zip'){
                link.setAttribute('download', filename + '.zip'); // Set the filename
            }
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            // Handle error
            commit(
                "setErrorMessage",
                "An error occurred while fetching data. Please try again."
            );

            console.error(err);
        } finally {
            commit("setButtonLoading", false);
        }
    }
};
