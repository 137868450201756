import getPrecioNeto from "./getPrecioNeto";

export default function getMejorPrecioMolecula(item:any) {

    const precioBs = getPrecioNeto(item, undefined,
        true, true, true, true)
    const precioFactor = getPrecioNeto(item, item?.prove_cfg?.FactorCambiario,
        true, true, true, true)

    return [parseFloat(precioBs).toFixed(2), parseFloat(precioFactor).toFixed(2)];
}