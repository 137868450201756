<template>
    <div class="justify-between" :class="cardSize" ref="card">
        <div class="font-bold text-xl justify-between flex p-2 rounded-t"
            :class="headerClass">{{ title }}
            <span><i :class='icon'></i></span>
        </div>
        <div :class="cardClass" class="rounded-b p-4 flex flex-col leading-normal " role="button">
            <div class="mb-2">
                <div ref="type2">
                    <div class="flex flex-wrap flex-col">

                        <div class="flex justify-between py-2">
                            <div>Código del cliente:</div>
                            <div class="flex justify-end">
                                <p class="font-bold">{{ content.codcli }}</p>
                            </div>
                        </div>
                        <div v-if="content.rif" class="flex justify-between py-2">
                            <div>Rif:</div>
                            <div class="flex justify-end">
                                <p class="font-bold">{{ content.rif }}</p>
                            </div>
                        </div>
                        <div v-if="content.contacto" class="flex justify-between py-2">
                            <div>Contacto:</div>
                            <div class="flex justify-end">
                                <p class="font-bold">{{ content.contacto }}</p>
                            </div>
                        </div>
                        <div v-if="content.keys" class="flex justify-between py-2">
                            <div>Keys:</div>
                            <div class="flex justify-end">
                                <p class="font-bold">{{ content.keys }}</p>
                            </div>
                        </div>
                        <div v-if="content.restanD" class="flex justify-between py-2">
                            <div>Restan Dias:</div>
                            <div class="flex justify-end">
                                <p class="font-bold">{{ content.restanD }}</p>
                            </div>
                        </div>
                        <div class="flex justify-between py-2">
                            <div>Estado:</div>
                            <div class="flex justify-end">
                                <div class="badge-success">{{ content.estado }}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'CardClient',
    props: ['title', 'content', 'cardClass', 'icon', 'cardSize', 'headerClass'],
    components: {
        
    },
    data() {
        return {}
    },
    mounted() {

    }
}
</script>

<style></style>
