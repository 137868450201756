import Swal from "sweetalert2";
import functions from "../..";
import { PedidoSelectedFilters } from "@/interfaces/Pedidos";
import { Axios } from "axios";

function getIndexados(indexados: any) {
    let html = `0.00`;

    if (indexados) {
        html = '';

        const parsedIndexed = JSON.parse(indexados);

        // Use map to generate an array of HTML elements
        const htmlElements = parsedIndexed.map((item: any) => {
            return `<span title="${item.description}" class="cursor-pointer">${parseFloat(item.amountWithoutPercent).toFixed(2)}</span> + `;
        });

        // Join the HTML elements and remove the trailing '+'
        html = htmlElements.join('').slice(0, -3); // Remove the last ' + '
    }

    return html;
}


async function getDetallePedido(axios: Axios, apiRoute: string, searchParams: PedidoSelectedFilters | {}) {


    try {
        const token = localStorage.getItem('authToken');
        if (!token) {
            throw new Error('Authentication token not found');
        }

        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(apiRoute + '/pedido/showOne', {
            headers,
            params: searchParams, // Use "params" instead of "searchParams" for Axios GET request.
        });

        return response;
    } catch (err) {
        console.log(err);
        return err;
    }

}

export default function openPedidoModal(id: string, axios: Axios, apiRoute: string) {
    let tabContainer; // Define the tabContainer variable

    Swal.fire({
        focusConfirm: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();

            const searchParams = {
                id
            };

            getDetallePedido(
                axios,
                apiRoute,
                searchParams
            ).then((response: any) => {
                // Close the loading modal
                Swal.close();
                let commentRow = '';
                if (response.data.comentario) {
                    commentRow = `<div class="text-sm col-span-3">
                            <p class="font-semibold">COMENTARIO:</p>
                            <p>${response.data.comentario}</p>
                        </div>`
                }
                // Create the HTML content with a tab panel
                let htmlContent = `
                <div id="modal-content">
                <ul class="flex">
                    <li class="w-1/2">
                        <a class="tab-link tab-1 w-full active" href="#default-tab">
                            General
                        </a>
                    </li>
                    <li class="w-1/2">
                        <a class="tab-link tab-2 w-full" href="#details-tab">
                            Detalles
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="default-tab">
                    <h2 class="tab-title mb-2">Informacion General</h2>
                    <div class="grid grid-cols-3 gap-4">
                        <div class="text-sm">
                            <p class="font-semibold">ID:</p>
                            <p>${response.data.id}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">CLIENTE:</p>
                            <p>${response.data.nomcli}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">RIF:</p>
                            <p>${response.data.rif}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">MARCA:</p>
                            <p>${response.data.marca}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">FECHA:</p>
                            <p>${response.data.fecha}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">RENGLONES:</p>
                            <p>${response.data?.pedren.length ?? 'N/A'}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">UNIDADES:</p>
                            <p>${response.data?.pedren?.reduce((accumulator: any, item: any) => accumulator + item.cantidad, 0) ?? 'N/A'}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">FECHA ENVIADO:</p>
                            <p>${response.data?.fecenviado ?? 'N/A'}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">USUARIO:</p>
                            <p>${response.data.usuario}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">ESTADO:</p>
                            <p>${response.data.estado}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">ORIGEN:</p>
                            <p>${response.data.origen}</p>
                        </div>
                        <div class="text-sm">
                            <p class="font-semibold">TOTAL:</p>
                            <p>${functions.prices.formatCurrency(parseFloat(response.data.total_pedido), 'es-VE', 'VES')} | ${functions.prices.formatCurrency(parseFloat(response.data.total_pedido_ext), 'en-US', "USD") ?? 'N/A'}</p>
                        </div>
                        ${commentRow}
                    </div>
                </div>

                <div class="hidden tab-content" id="details-tab">
                    <div class="max-h-72">
                        <h2 class="tab-title mb-2">Renglones del pedido</h2>
                        <div class="grid grid-cols-1 gap-0 sm:grid-cols-7">
                            <div class="grid-item hidden sm:block bg-gray-100 sticky top-0">DESCRIPCION</div>
                            <div class="grid-item hidden sm:block bg-gray-100 sticky top-0">CANTIDAD</div>
                            <div title="Descuento negociado por marca" class="grid-item hidden sm:block bg-gray-100 sticky top-0">DN</div>
                            <div title="Descuento negociado por producto" class="grid-item hidden sm:block bg-gray-100 sticky top-0">DP</div>
                            <div title="Descuentos indexados" class="grid-item hidden sm:block bg-gray-100 sticky top-0">DI</div>
                            <div class="grid-item hidden sm:block bg-gray-100 sticky top-0">NETO</div>
                            <div class="grid-item hidden sm:block bg-gray-100 sticky top-0">SUBTOTAL</div>
                `;

                response.data.pedren.map(async (item: any, index: number) => {
                    let grid_class;

                    if (index % 2 === 0) {
                        grid_class = 'bg-slate-200'
                    } else {
                        grid_class = 'bg-slate-white'
                    }
                    htmlContent += `
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-left text-sm">
                                <span class="sm:hidden font-bold">DESCRIPCION: </span>${item.desprod}
                            </div>
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-center text-sm">
                                <span class="sm:hidden font-bold">CANTIDAD: </span>${item.cantidad}
                            </div>
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-center text-sm">
                                <span class="sm:hidden font-bold">DN: </span>${getIndexados(response.data.desc_indexados_marca) ?? '0.00'}
                            </div>
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-center text-sm">
                                <span class="sm:hidden font-bold">DP: </span>${item.dn ?? '0.00'}
                            </div>
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-center text-sm">
                                <span class="sm:hidden font-bold">DM: </span>${getIndexados(item.desc_indexados)}
                            </div>
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-right text-sm">
                                <span class="sm:hidden font-bold">NETO: </span>${functions.prices.formatCurrency(item.neto, 'es-VE', 'VES')}
                            </div>
                            <div class="${grid_class} grid-item px-4 py-2 text-left sm:text-right text-sm">
                                <span class="sm:hidden font-bold">SUBTOTAL: </span>${functions.prices.formatCurrency(item.precio, 'es-VE', 'VES')}
                            </div>

                            `;
                });
                htmlContent += `
                        </div>
                    </div>
                </div>`;

                // Show the pedido information modal with the tab panel content
                Swal.fire({
                    focusConfirm: false,
                    title: `Pedido #${response.data.id}`,
                    html: htmlContent,
                    showConfirmButton: false,
                    didOpen: () => {
                        // Assign the tabContainer variable
                        tabContainer = document.querySelector('#modal-content') as HTMLElement;
                        tabContainer.addEventListener('click', function (event: any) {
                            if (event.target.classList.contains('tab-link')) {
                                event.preventDefault(); // Prevent default link behavior
                                const clickedLink = event.target;
                                const tabContentId = clickedLink.getAttribute('href');
                                const tabContent = document.querySelector(tabContentId);

                                // Toggle the 'active' class for the clicked tab link
                                document.querySelectorAll('.tab-link').forEach(link => {
                                    link.classList.remove('active');
                                });
                                clickedLink.classList.add('active');

                                // Hide/show tab content based on the clicked tab link
                                document.querySelectorAll('.tab-content').forEach(content => {
                                    content.classList.add('hidden');
                                });
                                tabContent.classList.remove('hidden');
                            }
                        });
                    },
                    customClass: {
                        popup: 'swal2-popup swal2-modal swal2-show swal-wide'
                    }
                });
            });
        }
    });
}




