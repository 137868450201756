<template>
	<div class="justify-between" :class="cardSize" ref="card">
		<div :class="cardClass" class="p-2 flex flex-col leading-normal">
			<div class="flex" id="inside-card">
				<div class="w-full">
					<!-- Display pedido information here -->
					<div class="flex justify-between">
						<h3 class="text-lg md:text-md lg:text-lg font-semibold">PEDIDO #{{ content.id }}: {{ content.marca
						}}</h3>

						<div class="w-12 flex justify-between">
							<button @click="downloadContent(content)"
								class="text-sky-500 hover:text-sky-700 focus:outline-none"
								v-tooltip="{ content: 'Descargar', triggers: ['hover'] }"
								>
								<i class='bx bx-download'></i>
							</button>
							<button @click="deleteItem(content)" class="text-red-500 hover:text-red-700 focus:outline-none"
								v-tooltip="'Eliminar de pedido'">
								<i class='bx bx-trash'></i>
							</button>
						</div>

					</div>
					<hr class="border-slate-300">
					<div class="grid grid-cols-1 lg:grid-cols-5 gap-1"
						@click="$functions.modals.openPedidoModal(content.id, $axios, $apiRoute)">
						<div class="flex flex-col">
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">FECHA:</span>{{ content.fecha
										?? 'N/A' }}</p>
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">ENVIADO:</span>{{
										content.fecenviado ?? 'N/A' }}</p>
						</div>

						<div class="flex flex-col">
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">ESTADO:</span>{{
										content.estado ?? 'N/A' }}</p>
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">ORIGEN:</span>{{
										content.origen ?? 'N/A' }}</p>
						</div>

						<div class="flex flex-col">
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">MARCA:</span>{{ content.marca
										?? 'N/A' }}</p>
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">USUARIO:</span>{{
										content.usuario ?? 'N/A' }}</p>
						</div>

						<div class="flex flex-col">
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">RENGLONES:</span>{{
										content.total_ren ?? 'N/A' }}</p>
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">UNIDADES:</span>{{
										content.total_unds ?? 'N/A' }}</p>
						</div>

						<div class="flex flex-col">
							<!-- <p><span class="font-semibold text-sm md:text-base lg:text-lg mr-2">AHORRO:</span>{{ (content?.total_costo - content.total_pedido)?.toFixed(2) ?? 'N/A' }}</p> -->
							<p class="whitespace-normal break-words"><span
									class="font-semibold text-sm md:text-base lg:text-lg mr-2">TOTAL:</span>{{
										formatCurrency(parseFloat(content?.total_pedido), 'es-VE', "VES") ?? 'N/A' }} |
								{{ formatCurrency(parseFloat(content?.total_pedido_ext), 'en-US', "USD") ?? 'N/A'
								}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';

export default {
	name: "CardPedidoList",
	props: [
		"title",
		"content",
		"cardClass",
		"cardSize",
		"headerClass",
		"index",
		"lastIndex",
	],
	components: {

	},
	computed: {
		...mapState("orders", ["cartItems", "cartItemsQuantity"]),
		...mapState("pedidos", ["buttonLoading"]),
	},
	data() {

		return {
			animate: false,
			imageSrc: `${this.$imgProdUrl}/${this.content.barra}.jpg`,
			altImageSrc: `${this.$imgProdUrl}/noimagen.jpg`,
			showModal: false,
			inputValue: this.content?.quantity,
			inputValue2: this.content?.discountItem,
			moleculaBs: this.$functions.prices.getPrecioMolecula(this.content)[0],
			molecula: this.$functions.prices.getPrecioMolecula(this.content)[1],
			localContent: { ...this.content },
		};
	},

	methods: {
		...mapActions("orders", ["fetchCartItemsQuantity", 'updateCartItemQuantity']),
		...mapActions('pedidos', ['getPedidos']),
		showSweetAlert(obj) {
			Swal.fire(obj);
		},
		closeModal() {
			this.showModal = false;
		},
		formatCurrency(value, locale, currency) {
			if (value) {
				return value.toLocaleString(locale, {
					style: "currency",
					currency: currency,
				});
			}
		},

		deleteItem(item) {
			// Show a confirmation dialog using SweetAlert
			Swal.fire({
				title: `¿Esta seguro de borrar el pedido #${item.id}: ${item.marca}?`,
				text: "Esta acción no se puede deshacer.",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#d33",
				cancelButtonColor: "#3085d6",
				confirmButtonText: "Eliminar",
				cancelButtonText: "Cancelar",
				focusConfirm: false,
				reverseButtons: true,
				customClass: {
					confirmButton: "custom-confirm-button",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					// Dispatch the removeFromCart action to remove the product from the cart in the Vuex store
					this.$store.dispatch('pedidos/deletePedido', {
						id: item.id,
						axios: this.$axios,
						apiRoute: this.$apiRoute
					}).then(() => {

						this.getPedidos({
							axios: this.$axios,
							apiRoute: this.$apiRoute,
							showPedidos: true
						});
						// Once the cart items quantity is updated, commit a mutation to update it in the state
						this.$store.commit('orders/updateCartItemsQuantity', this.cartItemsQuantity - item.total_ren);

						const Toast = Swal.mixin({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							timerProgressBar: true,
							didOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'El pedido ha sido eliminado.'
						})


					})
				}
			});
		},


		downloadContent(item) {
			const swalWithInput = Swal.mixin({
				input: 'select',
				confirmButtonText: 'Descargar <i class="fa fa-arrow-right"></i>',
				showCancelButton: true,
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Debes seleccionar una opción');
						}

						resolve();
					});
				},
				inputOptions: {
					'pdf': 'PDF',
					'excel': 'Excel',
					'zip': 'PDF y Excel'
				},
			});

			swalWithInput.fire({
				title: `Opciones de descarga del pedido #${item.id}: ${item.marca}`,
				iconHtml: '<i class="bx bx-file"></i>',
				confirmButtonColor: '#d33',
				cancelButtonColor: '#3085d6',
				cancelButtonText: 'Cancelar',
				focusConfirm: false,
				reverseButtons: true,
				showLoaderOnConfirm: true,
				customClass: {
					confirmButton: 'custom-confirm-button',
					icon: 'custom-icon-class',
					title: 'custom-title-class'
				},
				inputPlaceholder: 'Selecciona una opción',
				showCancelButton: true,
				preConfirm: (value) => {
					return new Promise((resolve, reject) => {
						// Deshabilitar el botón de cierre y el clic fuera del modal

						this.$store.dispatch('pedidos/downloadPedido', {
							item,
							axios: this.$axios,
							apiRoute: this.$apiRoute,
							searchParams: {
								type: value
							}
						})
							.then(() => {
								resolve();  // Resuelve la Promise si la descarga es exitosa
							})
							.catch(() => {
								reject('Error al descargar el pedido');  // Rechaza la Promise si hay un error
							});
					});
				}
			});

		}

	},
	watch: {
		"content.barra": function () {
			this.imageSrc = `${this.$imgProdUrl}/${this.content.barra}.jpg`;
			this.altImageSrc = `${this.$imgProdUrl}/noimagen.jpg`;
		},
		"content.quantity": function () {
			this.inputValue = this.content?.quantity;
		},
		showModal(newVal) {
			console.log("showModal changed:", newVal);
		},
	},
	mounted() {
		(this.imageSrc = `${this.$imgProdUrl}/${this.content.barra}.jpg`),
			(this.altImageSrc = `${this.$imgProdUrl}/noimagen.jpg`);
	},
};
</script>

<style>
.product-image {
	transition: transform 0.3s;
}

.product-image:hover {
	transform: scale(1.2);
	z-index: 99;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.6);
	position: relative;
}
</style>
