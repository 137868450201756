import { nextPage, prevPage } from "../pagination/paginationActions";

export function handleKeyDown(event: any, obj: any) {

    if (event.altKey && event.key === 'ArrowUp') {
        goUp(obj);
    }

    if (event.altKey && event.key === 'ArrowDown') {
        goDown(obj);
    }

    if (event.ctrlKey) {
        if (event.key === 'ArrowRight') {
            nextPage(obj, event);
        }

        if (event.key === 'ArrowLeft') {
            prevPage(obj, event);
        }

        if (event.key === 'ArrowDown') {
            focusOnNextInput(obj);
        }

        if (event.key === 'ArrowUp') {
            focusOnPrevInput(obj);
        }

        if (event.key === 'Enter') {
            try {
                // Focus on the first input of the next card-catalogo component
                const currentIndex: any = 0
                if (currentIndex !== -1 && currentIndex < obj.refCard.length - 1) {
                    const nextRefCard = obj.refCard[currentIndex + 1];
                    nextRefCard.focusOnInput();
                }
            } catch (error) {
                console.log(error);
            }

        }
    }
}


export function goUp(obj: any) {
    obj.scrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

export function goDown(obj: any) {
    obj.scrollContainer.scrollTo({
        top: obj.scrollContainer.scrollHeight,
        behavior: 'smooth'
    });
}

export function focusOnNextInput(obj: any) {
    try {
        const refCard = obj.refCard;


        const currentIndex = getCurrentFocusedIndex(refCard, obj);

        if (currentIndex !== -1 && currentIndex < refCard.length - 1) {
            const nextRefCard = refCard[currentIndex + 1];
            nextRefCard.focusOnInput();
        }
    } catch (e) {
        console.log(e);
    }

}

export function focusOnPrevInput(obj: any) {
    try {
        const refCard = obj.refCard;
        const currentIndex = getCurrentFocusedIndex(refCard, obj);

        if (currentIndex !== -1 && currentIndex > 0) {
            const nextRefCard = refCard[currentIndex - 1];
            nextRefCard.focusOnInput();
        }
    } catch (error) {
        console.log(error);
    }

}

export function getCurrentFocusedIndex(refCard: any, obj: any) {
    for (let index = 0; index < refCard.length; index++) {
        const input = getInputFromCardCatalogo(refCard[index]);

        if (isInputFocused(input, obj)) {
            return index;
        }
    }

    return -1;
}

export function isInputFocused(inputElement: any, obj: any) {
    return obj.document.activeElement === inputElement;
}

export function getInputFromCardCatalogo(cardCatalogo: any) {
    return cardCatalogo.$el.querySelector('input');
}
