import { HttpState } from ".";

export default {
    statusMessage(state:HttpState) {
        switch (state.status) {
            case 200:
                return "OK";
            case 201:
                return "Created";
            case 204:
                return "No Content";
            case 400:
                return "Bad Request";
            case 401:
                return "Unauthorized";
            // Add more cases here for other status codes
            default:
                return "Unknown Status";
        }
    },
};
