import form from "./form";
import time from "./time"
import http from "./http";
import prices from "./prices";
import * as modals from "./modals";
import shortcuts from "./shortcuts";
import pagination from "./pagination";
import tooltips from "./tootips";
import auth from "./auth";

export default {
    form,
    time,
    http,
    prices,
    modals,
    shortcuts,
    pagination,
    tooltips,
    auth
}


