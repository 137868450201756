<template>
    <div class="flex justify-between w-full border-t-2 my-1" id="dataCard">
        <!-- PRECIO - MEJOR PRECIO -->
        <div class="flex flex-col">

            <label class="flex flex-col ml-1"
            v-tooltip="{ content: 'Costo de compra del producto', triggers: ['hover', 'mouseleave'], autoHide: true}"
            role="button">
                <span class="text-xs font-medium text-slate-700">
                    COSTO:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class="bx bx-money"></i>
                    <span class="ml-1 text-xs">Bs.S {{
                        $functions.prices.getPrecioNeto(content, null, true, true, true)
                    }} </span>
                    <span class="ml-1 text-xs"> - $ {{
                        $functions.prices.getPrecioNeto(content, content?.prove_cfg?.FactorCambiario, true, true, true)
                    }}</span>
                </p>
            </label>

            <label class="flex flex-col ml-1" role="button"
                @click="$functions.modals.openMejorPrecioModal(content?.barra, $axios, $apiRoute)"
                v-tooltip="{ content: (content?.dataprod?.mejor_precio_bool) ? 'Tienes el mejor precio de venta entre todas las droguerias' : 'No tienes el mejor precio de venta entre todas las droguerias', triggers: ['hover', 'mouseleave'], autoHide: true}">
                <span class="text-xs font-medium text-slate-700">
                    MEJOR PRECIO DE VENTA:
                </span>
                <p v-if="content?.dataprod" class="flex font-semibold ml-1 text-xs items-center" :class="content?.dataprod?.mejor_precio_bool
                    ? 'bg-green-600 text-white w-fit rounded px-1'
                    : 'bg-red-600 text-white w-fit rounded px-1'
                    ">
                    <i v-if="content?.dataprod?.mejor_precio_bool" class="bx bx-check-circle"></i>
                    <i v-else class="bx bx-error"></i>
                    <span class="ml-1 text-xs" v-if="content.dataprod">{{
                        content?.dataprod?.mppliqBs?.toFixed(2)
                    }} - {{ content?.dataprod?.mppliq?.toFixed(2) }}</span>
                    <span class="ml-1 text-xs" v-else> 0 </span>
                </p>
                <p v-else class="flex font-semibold ml-1 text-xs items-center bg-green-600 text-white w-fit rounded px-1">
                    <i class="bx bx-check-circle"></i>
                    <span class="ml-1 text-xs">{{
                        parseFloat(content?.precio1).toFixed(2)
                    }} </span>
                    <span class="ml-1 text-xs"> - {{
                        (parseFloat(content?.precio1) /
                            parseFloat(content?.prove_cfg?.FactorCambiario)).toFixed(2)
                    }}</span>
                </p>
            </label>

        </div>

        <div class="flex flex-col">
            <label class="flex flex-col">
                <span class="text-xs font-medium text-slate-700">
                    COSTO MOLECULA:
                </span>
                <div class="flex" id="precio_molecula">
                    <p class="flex font-semibold ml-1 text-xs items-center">
                        <i class='bx bx-health'></i>
                        <span class="ml-1 text-xs" v-if="content.molecula != 'POR DEFINIR'">
                            Bs.S {{ $functions.prices.getPrecioMolecula(content)[0] }} -
                            $ {{ $functions.prices.getPrecioMolecula(content)[1] }}
                        </span>
                        <span class="ml-1 text-xs" v-else>
                            N/A
                        </span>
                    </p>
                </div>
            </label>
            <label class="flex flex-col"
                v-tooltip="{ content: 'Mejor precio de compra de la molecula por laboratorio', triggers: ['hover', 'mouseleave'], autoHide: true }"
                role="button"
                @click="$functions.modals.openMoleculasModal(content?.molecula, content?.barra, $axios, $apiRoute)">
                <span class="text-xs font-medium text-slate-700">
                    MEJOR PRECIO DE COMPRA:
                </span>
                <div class="flex" id="precio_molecula">
                    <p class="flex font-semibold ml-1 text-xs items-center" :class="content?.bool_marca
                        ? 'bg-green-600 text-white w-fit rounded px-1'
                        : 'bg-red-600 text-white w-fit rounded px-1'
                        " v-if="content.molecula != 'POR DEFINIR'">
                        <i v-if="content?.bool_marca" class="bx bx-check-circle mr-1"></i>
                        <i v-else class="bx bx-error mr-1"></i>
                        <span class="ml-1 text-xs">
                            Bs.S {{ $functions.prices.getMejorPrecioMolecula(content)[0] }} -
                            $ {{ $functions.prices.getMejorPrecioMolecula(content)[1] }}
                        </span>
                    </p>
                    <span class="ml-1 text-xs font-bold" v-else>
                        N/A
                    </span>
                </div>
            </label>
        </div>


        <!-- CODIGO DE BARRA - UNIDADES VENDIDAS -->
        <div class="flex flex-col">
            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    CODIGO DE BARRA:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class="bx bx-barcode"></i>
                    <span class="ml-1 text-xs">{{ content.barra }}</span>
                </p>
            </label>


            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    UNIDAD DEL BULTO:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class="bx bxs-cube"></i>
                    <span v-if="content.tpmBulto > 0" class="ml-1 text-xs">{{
                        content.tpmBulto
                    }}</span>
                    <span v-else class="ml-1 text-xs">{{ content.bulto }}</span>
                </p>
            </label>

        </div>

        <!-- MARCA - UNIDAD DE BULTO -->
        <div class="flex flex-col">
            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    MARCA:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class="bx bx-purchase-tag"></i>
                    <span class="ml-1 text-xs">{{ content.marca }}</span>
                </p>
            </label>

            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    SUBMARCA:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class='bx bxs-purchase-tag'></i>
                    <span class="ml-1 text-xs">{{ content.subgrupo }}</span>
                </p>
            </label>
        </div>

        <!-- INVENTARIO - CONSOLIDADO -->
        <div class="flex flex-col">
            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    INVENTARIO:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class="bx bx-archive-in"></i>
                    <span class="ml-1 text-xs">{{ content?.cantidad }}</span>
                </p>
            </label>

            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    UNIDADES EN TRANSITO:
                </span>
                <p class="flex font-semibold ml-1 text-xs items-center">
                    <i class="bx bx-car"></i>
                    <span class="ml-1 text-xs">{{ content.transito ?? 0 }}</span>
                </p>
            </label>
        </div>

        <!-- UNIDADES EN TRANISTO - LOTE -->
        <div class="flex flex-col">

            <label class="flex flex-col ml-1">
                <span class="text-xs font-medium text-slate-700">
                    DIAS EN INVENTARIO:
                </span>
                <p class="flex font-bold ml-1 text-xs items-center"><i class='bx bx-calendar-check'></i>
                    <span class="ml-1 text-xs">{{ Math.ceil(content?.dias) }}</span>
                </p>
            </label>

            <label class="flex flex-col ml-1" role="button" v-tooltip="{
                content: `
                VMD: ${content.vmd_inventario}<br>
                VMD GRUPO: ${content.vmd ?? 'N/A'}<br>
                VMD TOTAL: ${(parseFloat(content.vmd_inventario) + parseFloat(content.vmd ?? '0.0000')).toFixed(4)}`,
                html: true,// Configuración para permitir saltos de línea
            }"
            >
                <span class="text-xs font-medium text-slate-700">
                    VENTA MEDIA DIARIA:
                </span>
                <p class="flex font-bold ml-1 text-xs items-center"><svg xmlns="http://www.w3.org/2000/svg" width="12"
                        height="12" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
                        <path
                            d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z">
                        </path>
                        <path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
                    </svg>
                    <span class="ml-1 text-xs" v-if='content.vmd !== null'>{{ content.vmd_inventario }} | {{ content.vmd }}</span>
                    <span class="ml-1 text-xs" v-else>{{ content.vmd_inventario }}</span>
                </p>
            </label>
        </div>

        <!-- IVA - CODIGO DEL PRODUCTO -->
        <div class="flex flex-col">
            <label class="flex flex-col ml-1"
            v-tooltip="{ content: 'Cantidad sugerida de los productos a pedir', triggers: ['hover', 'mouseleave'], autoHide: true }"
            >
                <span class="text-xs font-medium text-slate-700">
                    SUGERIDO SEGUN DIAS:
                </span>
                <div class="flex">
                    <div>
                        <p class="flex font-bold ml-1 text-xs items-center">
                            <span class="ml-1 text-xs">
                                15 DIAS -> {{ Math.ceil(content?.sug15) }}</span>
                        </p>
                        <p class="flex font-bold ml-1 text-xs items-center">
                            <span class="ml-1 text-xs">
                                30 DIAS -> {{ Math.ceil(content?.sug30) }}</span>
                        </p>
                        <p class="flex font-bold ml-1 text-xs items-center">
                            <span class="ml-1 text-xs">
                                60 DIAS -> {{ Math.ceil(content?.sug60) }}</span>
                        </p>
                    </div>
                </div>
            </label>
        </div>

    </div>
</template>

<script>

export default {
    name: "ProductoInfo",
    props: [
        "title",
        "content",
        "cardClass",
        "cardSize",
        "headerClass",
        "index",
        "lastIndex",
        'type',
        'user'
    ],
    data() {

        return {
            showModal: false,
        }
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
    },
    inheritAttrs: false,
}
</script>
