import makeRequest from "./makeRequest";

export default async function getImage({ apiRoute, data }: { apiRoute: string; data: any }) {
    const token = localStorage.getItem(`authToken`)
    const url = (`${apiRoute}/cat/image?barra=${data.barra}&nomimagen=${data.nomimagen}`);
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    const method = 'GET';
    return makeRequest(url, data, config, method);

}
