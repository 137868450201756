import Swal from "sweetalert2";
import axios, { AxiosInstance } from 'axios'; // Import axios and AxiosInstance if not already imported
import store from "@/store";
import getPrecioNeto from "@/functions/prices/getPrecioNeto";

async function saveIndexedDiscounts(apiRoute: string, data: any = {}, url: string = "") {
    try {
        const token = localStorage.getItem('authToken'); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Create an Axios instance with the configured headers
        const axiosInstance: AxiosInstance = axios.create({
            baseURL: apiRoute,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Replace the following line with your actual API endpoint and request data
        const response = await axiosInstance.post(url, data);

        return response;
    } catch (err) {
        console.error(err);
        throw err; // Re-throw the error to propagate it
    }
}

function descuentosIndexadosModalAsync(product: any, items: any, apiRoute: string, isProduct: boolean) {
    return new Promise((resolve, reject) => {
        // Crear un contenedor de lista de descuentos
        let titleh2, titleh3;
        if (isProduct) {
            titleh2 = `Descuentos Indexados`;
            titleh3 = `PRODUCTO:<span class="italic ml-1">${product.desprod} - ${product.marca}</span>`;
        } else {
            titleh2 = `Descuentos Indexados por Marca`;
            titleh3 = `MARCA:<span class="italic ml-1">${product.marca}</span>`;
        }
        const discountListContainer = document.createElement('div');
        discountListContainer.innerHTML = `
    <h2 class="text-xl font-bold mb-4">${titleh2}</h2>
    <h3 class="text-sm font-bold mb-4">${titleh3}</h3>
        <div class="flex mb-4">
            <div class="flex flex-col">
                <input type="text" id="discount-description" placeholder="Descripción" class="border rounded-lg px-2 mr-2 mb-1">
                <input type="number" id="discount-amount" placeholder="% de descuento" class="border rounded-lg px-2 mr-2">
            </div>
            <div class="flex flex-col justify-between w-full">
                <div class="flex justify-between">
                    <button title="Añadir descuento" id="add-discount" class="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded-lg flex flex-wrap items-center justify-center w-2/5"><i class='bx bxs-plus-circle text-lg'></i></button>
                    <button title="Guardar descuentos" id="save-discounts" class="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded-lg flex flex-wrap items-center justify-center w-2/5"><i class='bx bx-save'></i></button>
                </div>
                <div id="total-amount" class="text-xl font-bold flex flex-wrap items-center justify-center">Total: 0%</div>
            </div>
        </div>

        <ul id="discount-list">
            <!-- Los elementos de la lista se agregarán aquí -->
        </ul>
    `;

        const addDiscountButton = discountListContainer.querySelector('#add-discount') as HTMLElement;
        const discountList = discountListContainer.querySelector('#discount-list') as HTMLElement;
        const discountDescription = discountListContainer.querySelector('#discount-description') as HTMLInputElement;
        const discountAmount = discountListContainer.querySelector('#discount-amount') as HTMLInputElement;
        const totalAmount = discountListContainer.querySelector('#total-amount') as HTMLElement;

        let totalDiscount: number | any = 100;

        // Función para actualizar la sumatoria
        function updateTotalAmount() {
            totalAmount.textContent = `Total: ${(100 - totalDiscount).toFixed(2)}%`;
        }

        if (items && isProduct) {
            items = JSON.parse(items);
            items.forEach((data: any) => {
                addDiscount(null, data);
            })
        } else {
            if (product?.desc_indexados) {
                product.desc_indexados.forEach((data: any) => {
                    addDiscount(null, data);
                })
            }
        }

        function addDiscount(evt: any, data: any = undefined) {

            let discountText: any;
            let discountNumber: any;
            if (data) {
                discountText = data.description.trim();
                discountNumber = data.amountWithoutPercentOri.trim();


            } else {
                discountText = discountDescription.value.trim();
                discountNumber = discountAmount.value.trim();
            }


            if (discountText !== '' && discountNumber !== '' && !isNaN(discountNumber) && discountNumber >= 0 && discountNumber <= 100) {
                if (!data) {
                    Swal.resetValidationMessage();
                }

                // Crear un nuevo elemento de lista
                const listItem = document.createElement('li');
                listItem.className = 'flex justify-between'; // Alinear el contenido a la izquierda y el botón a la derecha

                // Crear un div para contener los botones de "Editar" y "Eliminar"
                const buttonContainer = document.createElement('div');
                buttonContainer.className = 'flex mb-1';

                // Crear el botón "Editar" para editar este descuento
                const editButton = document.createElement('button');
                editButton.className = 'bg-yellow-500 text-white px-2 py-1 rounded-lg mr-2';
                editButton.title = 'Editar Descuento';

                // Crear un elemento de icono de Boxicons para el ícono de edición
                const editIcon = document.createElement('i');
                editIcon.className = 'bx bx-edit'; // Reemplaza 'bx-edit' con la clase del ícono que deseas utilizar

                // Agregar el elemento de icono al botón de "Editar"
                editButton.appendChild(editIcon);

                editButton.addEventListener('click', () => {
                    editDiscountItem(listItem);
                });

                // Crear el botón "Eliminar" para eliminar este descuento
                const deleteButton = document.createElement('button');
                deleteButton.className = 'bg-red-500 text-white px-2 py-1 rounded-lg';
                deleteButton.title = 'Eliminar Descuento';
                // Crear un elemento de icono de Boxicons para el ícono de eliminación
                const deleteIcon = document.createElement('i');
                deleteIcon.className = 'bx bx-trash'; // Reemplaza 'bx-trash' con la clase del ícono que desees utilizar

                // Agregar el elemento de icono al botón de "Eliminar"
                deleteButton.appendChild(deleteIcon);

                deleteButton.addEventListener('click', () => {
                    // Actualizar el totalDiscount al eliminar
                    removeDiscountItem(listItem);
                });


                // Contenido del elemento de lista
                const listContent = document.createElement('div');
                listContent.textContent = discountText + ': ' + discountNumber + '% | ' + (totalDiscount * parseFloat(discountNumber) / 100).toFixed(2) + '%';
                listContent.className = 'text-left';

                // Agregar los botones al contenedor de botones
                buttonContainer.appendChild(editButton);
                buttonContainer.appendChild(deleteButton);

                // Agregar el contenido y el contenedor de botones al elemento de lista
                listItem.appendChild(listContent);
                listItem.appendChild(buttonContainer);

                // Agregar el nuevo descuento a la lista
                discountList.appendChild(listItem);

                // Actualizar el totalDiscount al agregar
                totalDiscount = (totalDiscount - (totalDiscount * parseFloat(discountNumber) / 100)).toFixed(2);

                totalDiscount = parseFloat(totalDiscount);
                updateTotalAmount();

                // Limpiar los campos de entrada
                discountDescription.value = '';
                discountAmount.value = '';
            }
        }

        // Función para editar un elemento de la lista
        function editDiscountItem(item: any) {
            const currentText = item.textContent;
            const [description, amount] = currentText.split(': ');
            const [discount1, discount2] = amount.split(' | ');
            discountDescription.value = description;
            discountAmount.value = discount1.replace('%', '');
            const min = discount2.replace('%', '');

            // Actualizar el totalDiscount al editar
            const diff: any = ((100 - totalDiscount) - parseFloat(min));

            totalDiscount = (100 - diff);


            updateTotalAmount();

            // Eliminar el elemento de lista
            item.remove();
        }

        // Función para editar un elemento de la lista
        function removeDiscountItem(item: any) {
            const currentText = item.textContent;
            const [description, amount] = currentText.split(': ');
            const [discount1, discount2] = amount.split(' | ');
            const min = discount2.replace('%', '');

            // Actualizar el totalDiscount al editar
            const diff: any = ((100 - totalDiscount) - parseFloat(min));
            totalDiscount = (100 - diff);

            updateTotalAmount();

            // Eliminar el elemento de lista
            item.remove();
        }


        // Evento para comprobar si se presionó "Enter" en el campo de descripción
        discountDescription.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Evitar que se realice un salto de línea en el campo
                addDiscount(event);
            }
        });

        // Evento para comprobar si se presionó "Enter" en el campo de porcentaje de descuento
        discountAmount.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Evitar que se realice un salto de línea en el campo
                addDiscount(event);
            }
        });

        // Evento para verificar y eliminar el carácter ':' en el campo de descripción
        discountDescription.addEventListener('input', () => {
            const descriptionValue = discountDescription.value;
            if (descriptionValue.includes(':')) {
                // Eliminar el carácter ':' del valor del campo
                discountDescription.value = descriptionValue.replace(':', '');
            }

            if (descriptionValue.includes('|')) {
                // Eliminar el carácter '|' del valor del campo
                discountDescription.value = descriptionValue.replace('|', '');
            }
        });

        // Manejar la adición de un nuevo descuento cuando se hace clic en el botón "Agregar Descuento"
        addDiscountButton.addEventListener('click', addDiscount);

        const saveDiscountsButton = discountListContainer.querySelector('#save-discounts') as HTMLButtonElement;

        saveDiscountsButton.addEventListener('click', async () => {
            // Show the loading modal
            Swal.fire({
                allowOutsideClick: true,
                showCancelButton: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                },
            });

            // Crear un array para almacenar la información de descuentos
            const discountsInfo: Array<any> = [];

            // Recorrer los elementos de la lista de descuentos y obtener la información
            const discountItems = discountList.querySelectorAll('li');
            discountItems.forEach((item, index) => {
                // Obtener el texto del elemento
                const itemText: string | null = item.textContent;

                if (itemText) {
                    // Dividir el texto en dos partes: descripción y importe
                    const [description, amount] = itemText.split(': ');
                    const [discount1, discount2] = amount.split(' | ');
                    // Reemplazar el "%" por "" en el importe
                    const amountWithoutPercentOri = discount1.replace('%', '');
                    const amountWithoutPercent = discount2.replace('%', '');

                    // Agregar la información al array
                    discountsInfo.push({ index, description, amountWithoutPercent, amountWithoutPercentOri });
                }
            });

            try {
                if (isProduct) {
                    product.desc_indexados = JSON.stringify(discountsInfo);
                    product.total_indexado = totalDiscount;

                    const productData = {
                        ...product,
                        neto: getPrecioNeto(product, 1, true, true, true)
                    }

                    await saveIndexedDiscounts(apiRoute, productData, "/pedido/store");

                    store.commit('orders/updateIndexedDiscount', {
                        codprod: productData.codprod,
                        discount: productData.total_indexado,
                        jsonDiscounts: productData.desc_indexados
                    });

                } else {
                    const desc_indexados = JSON.stringify(discountsInfo);
                    const total_indexado = 100 - totalDiscount;

                    const data = {
                        marca: product.marca,
                        desc_indexados,
                        total_indexado
                    }

                    await saveIndexedDiscounts(apiRoute, data, "/pedido/updateIndexedDiscountByMarca");

                    store.commit('orders/updateIndexedDiscountMarca', {
                        marca: data.marca,
                        discount: data.total_indexado,
                        jsonDiscounts: data.desc_indexados
                    });

                }



                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizando productos...',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                });

                resolve(true);

            } catch (error) {
                reject(error);
            }

        });


        discountList.classList.add('max-h-60', 'overflow-y-auto');

        // Mostrar el modal con la lista de descuentos
        Swal.fire({
            html: discountListContainer,
            preConfirm: () => {
                const discountDescription = discountListContainer.querySelector('#discount-description') as HTMLInputElement;
                const discountAmount = discountListContainer.querySelector('#discount-amount') as HTMLInputElement;
                const discountText: any = discountDescription.value.trim();
                const discountNumber: any = discountAmount.value.trim();
                if (discountText !== '' && discountNumber !== '' && !isNaN(discountNumber) && discountNumber >= 0 && discountNumber <= 100) {
                    Swal.showValidationMessage('El maximo es un 100% de descuento, no se puede añadir más')
                    return false;
                } else {
                    Swal.resetValidationMessage();
                }
                return true;
            },
            showCancelButton: false,
            showConfirmButton: false,
        });

    });

}

export function openDescuentosIndexadosModal(apiRoute: string, product: any, marca: string = "", desc_indexado_marca: any = null) {
    Swal.showLoading();
    if (product) {
        return descuentosIndexadosModalAsync(product, product.desc_indexados, apiRoute, true);
    } else {
        const product = {
            marca,
            desc_indexados: (desc_indexado_marca) ? JSON.parse(desc_indexado_marca) : null
        }
        return descuentosIndexadosModalAsync(product, product.desc_indexados, apiRoute, false);
    }


}