<template>
    <div
        class="pagination flex sm:flex-row justify-center py-2 bg-white bg-opacity-60 backdrop-blur-lg rounded-b drop-shadow-lg mb-1">
        <div class="flex">
            <button @click="$functions.pagination.FirstPage(obj, $event)"
                class="pagination-btn bg-blue-500 hover:bg-blue-600 text-white font-bold flex items-center px-2 sm:px-4 rounded sm:mb-0 text-sm sm:text-base"
                :class="{ 'opacity-50 cursor-not-allowed': from === 1 }" :disabled="from === 1">
                <i class='bx bx-first-page text-base sm:text-xl'></i>
            </button>
            <button @click="$functions.pagination.prevPage(obj, $event)"
                class="pagination-btn bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 sm:py-2 px-2 sm:px-4 rounded mx-1 sm:mx-2 text-sm sm:text-base"
                :class="{ 'opacity-50 cursor-not-allowed': from === 1 }" :disabled="from === 1">
                Anterior
            </button>
        </div>
        <div class="flex">
            <button @click="$functions.pagination.nextPage(obj, $event)"
                class="pagination-btn bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 sm:py-2 px-2 sm:px-4 rounded mx-1 sm:mx-2 text-sm sm:text-base"
                :class="{ 'opacity-50 cursor-not-allowed': total === showing }" :disabled="total === showing">
                Siguiente
            </button>
            <button @click="$functions.pagination.LastPage(obj, $event)"
                class="pagination-btn bg-blue-500 hover:bg-blue-600 text-white font-bold flex items-center px-2 sm:px-4 rounded sm:mb-0 text-sm sm:text-base"
                :class="{ 'opacity-50 cursor-not-allowed': total === showing }" :disabled="total === showing">
                <i class='bx bx-last-page text-base sm:text-xl'></i>
            </button>
        </div>
    </div>
</template>
<script>

export default {
    name: "AppPagination",
    props: ['total', 'showing', 'from', 'obj'],
};
</script>