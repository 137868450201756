import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import '../public/css/app.css';
import 'floating-vue/dist/style.css';
import 'tw-elements';

import FloatingVue from 'floating-vue';
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";

import axios from 'axios';

import CardPedido from "./components/cards/CardPedido.vue";
import CardPedidoList from "./components/cards/CardPedidoList.vue";
import CardClient from './components/cards/CardClient.vue';
import CardCatalogo from './components/cards/CardCatalogo.vue';
import CardGraph from './components/cards/CardGraph.vue';
import CardOption from './components/cards/CardOption.vue';

import AppPagination from './components/app/AppPagination.vue';
import AppPageTitle from './components/app/AppPageTitle.vue';
import AppFooter from './components/app/AppFooter.vue';
import AppSidebar from './components/app/AppSidebar.vue';
import AppNavbar from './components/app/AppNavbar.vue';
import AppSelect from './components/app/AppSelect.vue';
import AppSearchInput from './components/app/AppSearchInput.vue';
import AppLoader from './components/app/AppLoader.vue';
import AppGoUpButton from './components/app/AppGoUpButton.vue';
import AppModal from './components/app/AppModal.vue';

import CartInput from './components/cart/CartInput.vue';

import functions from './functions';

const app = createApp(App);
if (process.env.NODE_ENV === 'production') {
    app.config.warnHandler = () => { };
}

app.use(store);
app.use(router);

FloatingVue.options.themes.tooltip = {
    ...FloatingVue.options.themes.tooltip,
    triggers: ['hover'],
    hideTriggers: (events:any) => [...events, 'click', 'mouseleave'],
    delay: {
        show: 200,
        hide: 0
    }
};
app.use(FloatingVue);

app.config.globalProperties.$apiRoute = process.env.VUE_APP_API_URL;
app.config.globalProperties.$imgProdUrl = process.env.VUE_APP_IMG_PROD_URL;
app.config.globalProperties.$imgProvUrl = process.env.VUE_APP_IMG_PROV_URL;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$functions = functions;

app.component("app-select", AppSelect);
app.component("app-footer", AppFooter);
app.component("app-sidebar", AppSidebar);
app.component("app-navbar", AppNavbar);
app.component("app-loader", AppLoader);
app.component("app-go-up-button", AppGoUpButton);
app.component("app-search-input", AppSearchInput);
app.component("app-modal", AppModal);
app.component('app-page-title', AppPageTitle);
app.component("cart-input", CartInput);
app.component("app-pagination", AppPagination);

app.component("card-client", CardClient);
app.component("card-graph", CardGraph);
app.component("card-option", CardOption);
app.component("card-catalogo", CardCatalogo);
app.component("card-pedido", CardPedido);
app.component("card-pedido-list", CardPedidoList);

app.component('vue-sidebar-menu-akahon', VueSidebarMenuAkahon);

app.mount('#app');
