<template>
    <div class="text-white p-3 flex justify-between bg-color-footer h-footer">
        <p class="flex flex-col footer-text">Copyright © 2013-2023 <span class="text-xs footer-text">ISB SISTEMAS, C.A.</span></p>
        <p class="footer-text">Version: 1.0.0</p>
    </div>
</template>
  
<script>
export default {
    name: 'AppFooter'
}
</script>
  
<style scoped>
/* Add any styles specific to the footer component here */
</style>
  