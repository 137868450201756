<template>
    <div class="flex items-center mb-4 mt-2 flex-wrap justify-between">
        <h2 class="text-white text-sm sm:text-2xl font-semibold">{{ title }}</h2>
        <div class="flex items-center flex-wrap">
            <div class="flex items-center bg-gray-100 rounded-lg p-2 mr-4 mt-1" v-if="localShow == true">
                <span class="font-bold text-sm sm:text-base">{{ titleItem }}: {{ localTotal }}</span>
            </div>
            <div class="flex items-center bg-gray-100 rounded-lg p-2 mr-4 mt-1"
                v-if="localShow == true && localFromPage != 'vendedores'">
                <span class="font-bold text-sm sm:text-base">Mostrando: {{ localFrom }} - {{ localShowing }}</span>
            </div>
            <div class="flex items-center bg-gray-100 rounded-lg p-2 mt-1 "
                v-if="localShow == true && localFromPage == 'vendedores'">
                <span class="font-bold text-sm sm:text-base">Marcas: {{ localMarcas }}</span>
            </div>


            <select v-model="localModel" class="mx-4 py-2 px-3 bg-gray-100 rounded-lg" v-if="localFromPage == 'vendedores'" @change="onSelectChange">
                <option value="">Todas las marcas</option>
                <option v-for="category in data" :key="category.name" :value="category.name">{{ category.name }}
                </option>
            </select>


            <div class="flex items-center bg-gray-100 rounded-lg p-2" @mouseenter="showText = true"
                @mouseleave="showText = false" role="button" @click="goBack">
                <span class="font-bold text-sm sm:text-base" v-show="!showText">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"
                        style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
                        <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z">
                        </path>
                    </svg>
                </span>
                <span class="font-bold text-sm sm:text-base" v-show="showText">Regresar</span>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: "AppPageTitle",
    props: ['total', 'from', 'showing', 'titleItem', 'title', 'fromPage', 'marcas', 'model', 'data', 'showCounters'],
    data() {
        return {
            showText: false,
            localModel: this.model,
            localShow: this.showCounters ?? true,
            localData: this.data ?? [],
            localMarcas: this.marcas ?? [],
            localShowing: this.showing ?? 0,
            localFrom: this.from ?? 0,
            localFromPage: this.fromPage ?? 0,
            localTotal: this.total ?? 0
        }
    },
    watch: {
        marcas(newMarcas) {
            // Update the local marcas when the marcas prop changes
            this.localMarcas = newMarcas;
        },
        showing(newShowing) {
            // Update the local showing when the showing prop changes
            this.localShowing = newShowing;
        },
        from(newFrom) {
            // Update the local from when the from prop changes
            this.localFrom = newFrom;
        },
        fromPage(newFromPage) {
            // Update the local fromPage when the fromPage prop changes
            this.localFromPage = newFromPage;
        },
        total(newTotal) {
            // Update the local total when the total prop changes
            this.localTotal = newTotal;
        }
    },
    methods: {
        mouseenter() {
            this.showText = true;
        },
        mouseleave() {
            this.showText = false;
        },
        goBack() {
            // Use Vue Router to navigate back in the browser history
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                // If it is not possible to go back in the history, redirect to the home page
                this.$router.push('/inicio');
            }
        },
        onSelectChange(event) {
            // Emit an event with the selected value
            this.$emit('option-selected', event.target.value);
        },


    }
}
</script>

