<template>

	<div v-if="index === 0 && currentPage !== 1" class="card-list-body cursor-pointer" @click="emitShowPreviousEvent">
		<p>Mostrar anteriores<span><i class='pl-2 bx bxs-up-arrow'></i></span></p>
	</div>
	<div :class="{ 'justify-between': true, [cardSize]: true}" ref="card">
		<div :class="cardClass" class="p-2 flex flex-col leading-normal">
			<div class="flex" id="inside-card">
				<div class="flex flex-wrap justify-center one">
					<div id="img-box">
						<img :src="imageSrc" loading="lazy" class="img-responsive product-image rounded"
							@error="$event.target.src = altImageSrc" width="105" oncontextmenu="return false" role="button"
							@click="$functions.modals.openProductModal($axios, $apiRoute, content.barra, imageSrc, altImageSrc)"
							height="auto" />
					</div>
				</div>
				<div class="parent w-full">
					<div class="div1">
						<div class="flex justify-between w-full flex-wrap">

							<div class="flex flex-wrap">
								<div>
									<cart-input :type="'text'" v-model="inputValue" @add-to-cart="handleCart"
										:value="inputValue" :content="content" @quality="updateValue"
										@discount="updateDiscountItem" :value2="inputValue2" icon='bx bx-edit-alt'
										text="Editar cantidad" :hasDiscount="true" :hasInedexesDiscount="true"
										@open-modal="inputModalHandler(content)" :CampoVmdInv="CampoVmdInv"></cart-input>
								</div>
								<label class="flex flex-col ml-1">
									<span class="text-xs font-medium text-slate-700">
										DESCRIPCION:
									</span>
									<div class="flex" id="descuentos">
										<p class="flex font-bold ml-1 text-xs items-center">
											<i class="bx bxs-capsule"></i>{{ title }} -
											{{ localContent.codprod }}
										</p>
									</div>
								</label>
								<label class="flex flex-col mx-4">
									<span class="text-xs font-medium text-slate-700">
										MOLECULA:
									</span>
									<div class="flex" id="molecula">
										<p class="flex font-bold ml-1 text-xs items-center">
											<i class='bx bx-dna'></i>
											{{ localContent.molecula }}
										</p>
									</div>
								</label>
								<label class="flex flex-col mx-4">
									<span class="text-xs font-medium text-slate-700">
										UNIDAD MOLECULA:
									</span>
									<div class="flex" id="precio_molecula">
										<p class="flex font-bold text-xs items-center">
											<i class='bx bx-shape-triangle mr-1'></i>
											{{ (localContent?.dataprod?.unidadmolecula || 1) }}
										</p>
									</div>
								</label>
							</div>
							<div class="flex items-center">
								<!-- Add the 'X' icon for removing the item -->
								<div class="flex">
									<span v-if="(100 - content.total_indexado) > 0 && content.desc_indexados"
										class="ml-1 text-md bg-sky-500 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center items-center py-0"
										v-tooltip="{
											content: $functions.tooltips.indexedDiscounts(content),
											html: true
										}" role="button"> +{{ parseFloat(100-content.total_indexado).toFixed(2) }}% | DI </span>
									<span v-if="localContent.iva > 0"
										class="ml-1 text-md bg-sky-400 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center py-0"
										v-tooltip="'Porcentaje de IVA agregado'">+{{
											localContent.iva }}% |
										IVA</span>
									<span v-if="content.discountMarca > 0"
										class="ml-1 text-md bg-sky-500 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center py-0"
										v-tooltip="{
											content: $functions.tooltips.indexedDiscountsMarca(desc_indexados_marca),
											html: true
										}"  role="button">+{{
											content.discountMarca }}% |
										DN</span>
									<span v-if="content.discountItem > 0"
										class="ml-1 text-md bg-sky-500 text-white px-1 rounded font-semibold w-fit flex flex-wrap content-center py-0"
										v-tooltip="'Descuento negociado por el producto'" role="button">+{{
											content.discountItem }}% |
										DP</span>

								</div>
								<button @click="removeItem(content)"
									class="text-red-500 hover:text-red-700 focus:outline-none ml-4"
									v-tooltip="'Eliminar del carrito'">
									<i class='bx bx-trash'></i>
								</button>


							</div>
						</div>

					</div>
					<div class="flex">
						<ProductoInfo :title="title" :content="content" :cardClass="cardClass" :cardSize="cardSize"
							:headerClass="headerClass" :index="index" :lastIndex="lastIndex" type="1" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div v-if="index === lastIndex && currentPage !== lastPage" class="card-list-body cursor-pointer"  @click="emitShowMoreEvent">
		<p>Mostrar siguientes<span><i class='pl-2 bx bxs-down-arrow'></i></span></p>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import ProductoInfo from './cardContent/producto/ProductoInfo.vue';
import { mapState, mapActions } from 'vuex';

export default {
	name: "CardPedido",
	props: [
		"title",
		"content",
		"cardClass",
		"cardSize",
		"headerClass",
		"index",
		"lastIndex",
		"currentPage",
		'lastPage',
		'CampoVmdInv',
		'desc_indexados_marca'
	],
	components: {
		ProductoInfo
	},
	computed: {
		...mapState("orders", ["cartItems"]),
	},
	emits: ['item-removed', 'contentUpdated', 'show-more', 'show-previous', 'open-input-modal'],
	data() {
		return {
			animate: false,
			imageSrc: `${this.$imgProdUrl}/${this.content.barra}.jpg`,
			altImageSrc: `${this.$imgProdUrl}/noimagen.jpg`,
			inputValue: this.content?.quantity,
			inputValue2: this.content?.discountItem,
			moleculaBs: this.$functions.prices.getPrecioMolecula(this.content)[0],
			molecula: this.$functions.prices.getPrecioMolecula(this.content)[1],
			localContent: { ...this.content },
		};
	},

	methods: {
		...mapActions("orders", ["addToCart", "fetchCartItemsQuantity", "removeOneFromCart", "updateCartItemDiscount", "updateCartItemQuantity"]),
		inputModalHandler(content){
			this.$emit('open-input-modal', content);
		},
		showSweetAlert(obj) {

			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', Swal.stopTimer)
					toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})

			Toast.fire(obj);
		},
		updateValue(item) {
			this.inputValue = item;

			const updatedContent = {
				...this.localContent,
				quantity: parseFloat(item),
			};

			this.updateCartItemQuantity({
				product: updatedContent, // Use the updated content with the new quantity value
				quantity: parseFloat(item),
				axios: this.$axios,
				apiRoute: this.$apiRoute
			}).then(() => {
				// Show a success SweetAlert modal
				Swal.fire({
					title: 'Actualizado',
					text: 'La cantidad del producto ha sido actualizada.',
					icon: 'success',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK',
					focusConfirm: false,
					customClass: {
						confirmButton: 'custom-confirm-button',
					},
				});

				this.$emit('content-updated', updatedContent);
			})
				.catch((error) => {
					Swal.fire('Error', 'Ocurrió un error al actualizar la cantidad del pedido.', 'error');
					console.error(error);
				});
		},
		updateDiscountItem(item) {
			const discount = parseFloat(item).toFixed(2);

			if (discount < 0 || discount > 100) {
				Swal.fire({
					title: 'Advertencia',
					text: 'El descuento debe estar entre 0 y 100.',
					icon: 'warning',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK',
					focusConfirm: false,
					customClass: {
						confirmButton: 'custom-confirm-button',
					},
				});
				return; // No need to proceed with dispatching the action
			}

			this.inputValue2 = discount;

			this.localContent.discountItem = discount;

			this.updateCartItemDiscount({
				product: {
					...this.localContent,
					neto: this.$functions.prices.getPrecioNeto(this.localContent, null, true, true, true),
				},
				discount: discount,
				axios: this.$axios,
				apiRoute: this.$apiRoute,
			})
				.then(() => {
					// Show a success SweetAlert modal
					const Toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timerProgressBar: true,
						didOpen: (toast) => {
							toast.addEventListener('mouseenter', Swal.stopTimer)
							toast.addEventListener('mouseleave', Swal.resumeTimer)
						}
					})

					Toast.fire({
						icon: 'success',
						title: 'Guardando cambios...'
					})

					// Update content as well
					this.localContent.discountItem = discount;
					const updatedContent = {
						...this.content,
						costo: this.$functions.prices.getPrecioNeto(this.localContent, null, true, true),
					};

					this.$emit('content-updated', updatedContent);
				})
				.catch((error) => {
					Swal.fire('Error', 'Ocurrió un error al actualizar el descuento.', 'error');
					console.error(error);
				});
		},
		handleCart(product) {
			// Get the input value (quantity)
			const quantity = parseFloat(this.inputValue);
			// Check if the input value is less than or equal to 0
			if (isNaN(quantity) || quantity <= 0) {
				// Show an alert to the user
				let obj = {
					title: 'Ups!!!',
					text: 'La cantidad ingresada debe ser mayor a 0',
					icon: 'error',
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'custom-confirm-button', // Apply the custom class to the confirm button
					},
				}
				this.showSweetAlert(obj)
				return; // Exit the function to prevent adding the product to the cart
			}

			// Dispatch the addToCart action to add the product to the cart in the Vuex store
			const productToAdd = {
				...product,
				quantity,
			};

			this.addToCart({
				axios: this.$axios,
				apiRoute: this.$apiRoute,
				router: this.$router,
				data: productToAdd
			});
			// this.$store.dispatch("orders/addToCart", productToAdd);
		},
		removeItem(product) {
			// Show a confirmation alert before removing the item
			Swal.fire({
				title: 'Confirmación',
				text: '¿Deseas eliminar este producto del carrito?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sí',
				focusConfirm: false,
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					// Dispatch the removeFromCart action to remove the product from the cart in the Vuex store
					this.removeOneFromCart({
						index: product.barra,
						product: product,
						axios: this.$axios,
						apiRoute: this.$apiRoute
					})
						.then(() => {
							const Toast = Swal.mixin({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 3000,
								timerProgressBar: true,
								didOpen: (toast) => {
									toast.addEventListener('mouseenter', Swal.stopTimer)
									toast.addEventListener('mouseleave', Swal.resumeTimer)
								}
							})

							Toast.fire({
								icon: 'success',
								title: 'El producto fue eliminado del pedido...'
							})
							this.$emit('item-removed'); // Optional: Emit an event to notify parent components
						})
						.catch((error) => {
							Swal.fire('Error', 'Ocurrió un error al eliminar el producto del carrito.', 'error');
							console.error(error);
						});

				}
			});
		},
		emitShowMoreEvent() {
			// Emit a custom event named 'show-more' when "Mostrar mas..." is clicked
			this.$emit('show-more');
		},
		emitShowPreviousEvent(){
			this.$emit('show-previous');
		}
	},
	watch: {
		"content.barra": function () {
			this.imageSrc = `${this.$imgProdUrl}/${this.content.barra}.jpg`;
			this.altImageSrc = `${this.$imgProdUrl}/noimagen.jpg`;
		},
		"content.quantity": function () {
			this.inputValue = this.content?.quantity;
		},
		"content.discountItem": function () {
			this.inputValue2 = this.content?.discountItem;
		},
		"content": function () {
			this.localContent = {
				...this.content
			}
		},
	},
	inheritAttrs: false,
};
</script>

<style>
.product-image {
	transition: transform 0.3s;
}

.product-image:hover {
	transform: scale(1.2);
	z-index: 99;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.6);
	position: relative;
}
</style>
