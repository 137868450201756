import Swal from "sweetalert2";
import store from "@/store";

async function getFiltros(axios: any, apiRoute: any) {
    try {
        const token = localStorage.getItem("authToken"); // Replace 'yourTokenKeyName' with the actual key name used to store the token

        // Configure headers with the token
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const response = axios.get(`${apiRoute}/cat/filtros`, {
            headers,
        });

        return response;
    } catch (err) {
        console.log(err);
    }
}

// function setRangeHTML(data: any) {
//     let rangoDesde = 50;
//     let checkDesde = false;
//     let htmlCheckDesde = ``;
//     let rangoHasta = 50;
//     let checkHasta = false;
//     let htmlCheckHasta = ``;

//     if (data?.rangoDesde) {
//         rangoDesde = data.rangoDesde;
//     }

//     if (data?.rangoHasta) {
//         rangoHasta = data.rangoHasta;
//     }

//     if (data?.selectFrom) {
//         checkDesde = true;
//     }

//     if (data?.selectTo) {
//         checkHasta = true;
//     }

//     if (checkDesde) {
//         htmlCheckDesde = `checked`;
//     }

//     if (checkHasta) {
//         htmlCheckHasta = `checked`;
//     }

//     return `
//     <div class="flex items-center">
//         <h2 class="text-lg font-semibold">Selecciona el rango de la venta media diaria:</h2>
//     </div>

//     <div class="grid grid-cols-2 gap-4">
//         <div>
//             <div class="flex items-center">
//                 <input type="checkbox" class="mr-2 mb-2" value="selectFrom" id="selectFrom"  ${htmlCheckDesde}>
//                 <h3 class="text-md font-semibold mb-2">Desde:</h3>
//             </div>

//             <input type="range" min="0" max="100" value="${rangoDesde}" class="w-full h-6 appearance-none bg-gray-300 rounded-lg" id="rangeSlider1">
//             <p class="mt-2"> >= <span class="font-semibold" id="sliderValue1">${rangoDesde}</span></p>
//         </div>
//         <div>
//             <div class="flex items-center">
//                 <input type="checkbox" class="mr-2 mb-2" value="selectTo" id="selectTo" ${htmlCheckHasta}>
//                 <h3 class="text-md font-semibold mb-2">Hasta:</h3>
//             </div>

//             <input type="range" min="0" max="100" value="${rangoHasta}" class="w-full h-6 appearance-none bg-gray-300 rounded-lg" id="rangeSlider2" >
//             <p class="mt-2"> <= <span class="font-semibold" id="sliderValue2">${rangoHasta}</span></p>
//         </div>
//     </div>
//     `;
// }

function setOptionsMarcaHtml(data: any) {
    let marcas = data.marcas;
    if (data?.checkboxStatesMarca?.length > 0) {
        marcas = data.checkboxStatesMarca;
    }

    let html = `
    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <input type="checkbox" class="mr-2" value="selectAllOptions" id="selectAllOptionsMarca" checked>
            <h2 class="my-2 text-lg font-semibold mb-2">Selecciona las marcas:</h2>
        </div>
        <div class='pr-1'>
            <button title="Seleccionar todos" id="selectAllButtonMarca" class="text-blue-500 hover:text-blue-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-check inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Seleccionar Todos -->
            </button>
            <button title="Deseleccionar todos" id="deselectAllButtonMarca" class="text-red-500 hover:text-red-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-minus inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Deseleccionar Todos -->
            </button>
        </div>
    </div>

    <!-- Add a wrapper div around the input element -->
    <div class="my-2 flex items-center border border-gray-300 rounded">
        <!-- Add the BoxIcon search icon -->
        <i class='bx bx-search text-gray-400 ml-2'></i>
        <!-- Add some classes to the input element to style it -->
        <input
            type="text"
            id="filterMarcasInput"
            placeholder="Filtrar marcas..."
            class="w-full py-1 px-2 outline-none"
        >
    </div>


    <div class="max-h-80 overflow-auto">
        <div class="grid grid-cols-2 gap-2" id="marcasContainer">`;

    marcas.map((item: any) => {
        html += `
                <label class="flex items-center text-left">
                    <input type="checkbox" class="mr-2" value="${item?.marca ?? item?.name
            }" name="checkboxOptionMarca" ${item?.checked === true ? "checked" : ""
            }>
                    ${item?.marca ?? item?.name}
                </label>
                `;
    });

    html += `
        </div>
    </div>`;

    return html;
}

function setOptionsMoleculaHtml(data: any) {
    let moleculas = data.moleculas;
    if (data?.checkboxStatesMolecula?.length > 0) {
        moleculas = data.checkboxStatesMolecula;
    }

    let html = `
    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <input type="checkbox" class="mr-2" value="selectAllOptions" id="selectAllOptionsMolecula" checked>
            <h2 class="my-2 text-lg font-semibold mb-2">Selecciona las moleculas:</h2>
        </div>
        <div>
            <button title="Seleccionar todos" id="selectAllButtonMolecula" class="text-blue-500 hover:text-blue-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-check inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Seleccionar Todos -->
            </button>
            <button title="Deseleccionar todos" id="deselectAllButtonMolecula" class="text-red-500 hover:text-red-600 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                <i class='bx bx-list-minus inline-block align-middle' style="font-size:30px;"></i> <!-- Icono para Deseleccionar Todos -->
            </button>
        </div>
    </div>

    <!-- Add a wrapper div around the input element -->
    <div class="my-2 flex items-center border border-gray-300 rounded">
        <!-- Add the BoxIcon search icon -->
        <i class='bx bx-search text-gray-400 ml-2'></i>
        <!-- Add some classes to the input element to style it -->
        <input
            type="text"
            id="filterMoleculasInput"
            placeholder="Filtrar moleculas..."
            class="w-full py-1 px-2 outline-none"
        >
    </div>

    <div class=" max-h-80 overflow-auto">

        <div class="grid grid-cols-2 gap-2" id="moleculasContainer">`;

    moleculas.map((item: any) => {
        html += `
                <label class="flex items-center text-left">
                    <input type="checkbox" class="mr-2" value="${item?.molecula ?? item?.name
            }" name="checkboxOptionMolecula" ${item?.checked ? "checked" : ""
            }>
                    ${item?.molecula ?? item?.name}
                </label>
                `;
    });

    html += `
        </div>
    </div>`;

    return html;
}

function setHtml(data: any) {
    const html = `
    <div class="grid gap-4 sm:grid-cols-1 lg:grid-cols-2">

        <div>
            ${setOptionsMarcaHtml(data)}
        </div>

        <div>
            ${setOptionsMoleculaHtml(data)}
        </div>

    </div>
`;

    return html;
}

export default function openFiltrosCatalogoModal(axios: any, apiRoute: any) {
    Swal.showLoading();

    // Access the getters
    const selectedFilters = store.getters["catalog/selectedFilters"];
    const selectedRange = store.getters["catalog/selectedRange"];

    getFiltros(axios, apiRoute).then((response) => {
        const data = {
            ...response.data,
            ...selectedRange,
            ...selectedFilters,
        };

        const html = setHtml(data);
        Swal.fire({
            title: "Opciones de Filtrado",
            html,
            showCancelButton: true,
            confirmButtonText: "Aplicar",
            cancelButtonText: "Cancelar",
            focusConfirm: false,
            reverseButtons: true,
            customClass: {
                confirmButton: "custom-confirm-button",
                popup: "swal2-popup swal2-modal swal2-show swal-wide",
            },
            preConfirm: () => {
                const checkOptionsMarcaElement = document.getElementById(
                    "selectAllOptionsMarca"
                ) as HTMLInputElement;
                const checkOptionsMarca = checkOptionsMarcaElement.checked;

                const checkOptionsMoleculaElement = document.getElementById(
                    "selectAllOptionsMolecula"
                ) as HTMLInputElement;
                const checkOptionsMolecula = checkOptionsMoleculaElement.checked;

                // const selectFromElement = document.getElementById(
                //     "selectFrom"
                // ) as HTMLInputElement;
                // const selectFrom = selectFromElement.checked;

                // const selectToElement = document.getElementById(
                //     "selectTo"
                // ) as HTMLInputElement;
                // const selectTo = selectToElement.checked;

                // const selectedValue1Element = document.getElementById(
                //     "sliderValue1"
                // ) as HTMLInputElement;
                // const selectedValue1 = selectedValue1Element.textContent;

                // const selectedValue2Element = document.getElementById(
                //     "sliderValue2"
                // ) as HTMLInputElement;
                // const selectedValue2 = selectedValue2Element.textContent;

                const optionCheckboxesMarca = document.querySelectorAll(
                    'input[type="checkbox"][name^="checkboxOptionMarca"]'
                ) as NodeList;
                const checkboxStatesMarca: any = [];
                const optionCheckboxesMolecula = document.querySelectorAll(
                    'input[type="checkbox"][name^="checkboxOptionMolecula"]'
                ) as NodeList;
                const checkboxStatesMolecula: any = [];

                optionCheckboxesMarca.forEach((checkbox: any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.value,
                        checked: checkbox.checked,
                    });
                });

                optionCheckboxesMolecula.forEach((checkbox: any) => {
                    checkboxStatesMolecula.push({
                        name: checkbox.value,
                        checked: checkbox.checked,
                    });
                });

                return {
                    checkOptionsMarca,
                    checkOptionsMolecula,
                    // rangoDesde: selectedValue1,
                    // rangoHasta: selectedValue2,
                    // selectFrom,
                    // selectTo,
                    checkboxStatesMarca,
                    checkboxStatesMolecula,
                };
            },
            didOpen: () => {
                // const rangeSlider1 = document.getElementById("rangeSlider1") as HTMLInputElement;
                // const rangeSlider2 = document.getElementById("rangeSlider2") as HTMLInputElement;
                // const sliderValue1 = document.getElementById("sliderValue1") as HTMLElement;
                // const sliderValue2 = document.getElementById("sliderValue2") as HTMLElement;
                const filterInputMarcas = document.getElementById("filterMarcasInput") as HTMLElement;
                const filterInputMoleculas = document.getElementById(
                    "filterMoleculasInput"
                ) as HTMLElement;
                const marcasContainer = document.getElementById("marcasContainer") as HTMLElement;
                const moleculasContainer =
                    document.getElementById("moleculasContainer") as HTMLElement;

                const selectAllButtonMarca = document.getElementById(
                    "selectAllButtonMarca"
                );
                const deselectAllButtonMarca = document.getElementById(
                    "deselectAllButtonMarca"
                );
                const optionCheckboxesMarca = document.querySelectorAll(
                    'input[type="checkbox"][name^="checkboxOptionMarca"]'
                );
                const checkboxStatesMarca = [];

                optionCheckboxesMarca.forEach((checkbox: any) => {
                    checkboxStatesMarca.push({
                        name: checkbox.name,
                        checked: checkbox.checked,
                    });
                });

                if (selectAllButtonMarca) {
                    selectAllButtonMarca.addEventListener("click", () => {
                        optionCheckboxesMarca.forEach((checkbox: any) => {
                            checkbox.checked = true;
                        });
                    });
                }

                if (deselectAllButtonMarca) {
                    deselectAllButtonMarca.addEventListener("click", () => {
                        optionCheckboxesMarca.forEach((checkbox: any) => {
                            checkbox.checked = false;
                        });
                    });
                }

                const selectAllButtonMolecula = document.getElementById(
                    "selectAllButtonMolecula"
                );
                const deselectAllButtonMolecula = document.getElementById(
                    "deselectAllButtonMolecula"
                );
                const optionCheckboxesMolecula = document.querySelectorAll(
                    'input[type="checkbox"][name^="checkboxOptionMolecula"]'
                );
                const checkboxStatesMolecula = [];

                optionCheckboxesMolecula.forEach((checkbox: any) => {
                    checkboxStatesMolecula.push({
                        name: checkbox.name,
                        checked: checkbox.checked,
                    });
                });

                if (selectAllButtonMolecula) {
                    selectAllButtonMolecula.addEventListener("click", () => {
                        optionCheckboxesMolecula.forEach((checkbox: any) => {
                            checkbox.checked = true;
                        });
                    });
                }

                if (deselectAllButtonMolecula) {
                    deselectAllButtonMolecula.addEventListener("click", () => {
                        optionCheckboxesMolecula.forEach((checkbox: any) => {
                            checkbox.checked = false;
                        });
                    });
                }

                // rangeSlider1.addEventListener("input", () => {
                //     sliderValue1.textContent = rangeSlider1.value;
                // });

                // rangeSlider2.addEventListener("input", () => {
                //     sliderValue2.textContent = rangeSlider2.value;
                // });

                // Add an event listener to the filter input element to handle user input
                filterInputMarcas.addEventListener("input", (event:any) => {
                    // Get the current value of the filter input
                    const filterValue = event.target.value.toLowerCase();

                    // Get all label elements within the marcas container
                    const labels = marcasContainer.querySelectorAll("label");

                    // Loop over each label element
                    labels.forEach((label:any) => {
                        // Get the text content of the label
                        const labelText = label.textContent.toLowerCase();

                        // Check if the label text includes the filter value
                        if (labelText.includes(filterValue)) {
                            // If it does, show the label
                            label.style.display = "flex";
                        } else {
                            // If it doesn't, hide the label
                            label.style.display = "none";
                        }
                    });
                });

                // Add an event listener to the filter input element to handle user input
                filterInputMoleculas.addEventListener("input", (event:any) => {
                    // Get the current value of the filter input
                    const filterValue = event.target.value.toLowerCase();

                    // Get all label elements within the marcas container
                    const labels = moleculasContainer.querySelectorAll("label");

                    // Loop over each label element
                    labels.forEach((label:any) => {
                        // Get the text content of the label
                        const labelText = label.textContent.toLowerCase();

                        // Check if the label text includes the filter value
                        if (labelText.includes(filterValue)) {
                            // If it does, show the label
                            label.style.display = "flex";
                        } else {
                            // If it doesn't, hide the label
                            label.style.display = "none";
                        }
                    });
                });
            },
        }).then((result:any) => {
            if (result.isConfirmed) {
                store.dispatch("catalog/setSelectedFilters", {
                    checkOptionsMarca: result.value.checkOptionsMarca,
                    checkOptionsMolecula: result.value.checkOptionsMolecula,
                    checkboxStatesMarca: result.value.checkboxStatesMarca,
                    checkboxStatesMolecula: result.value.checkboxStatesMolecula,
                });

                store.dispatch("catalog/setSelectedRange", {
                    rangoDesde: result.value.rangoDesde,
                    rangoHasta: result.value.rangoHasta,
                    selectFrom: result.value.selectFrom,
                    selectTo: result.value.selectTo,
                });

                store.dispatch("catalog/getCatalogo", {
                    axios,
                    apiRoute,
                    searchParams: {
                        filtro: null,
                        ...result.value,
                    },
                });
            }
        });
    });
}
