// views/index.js
const CatalogoView = () => import('./catalogo/CatalogoView.vue');
const CatalogoConfigView = () => import('./catalogo/config/ConfigView.vue');
const WelcomeView = () => import('./home/WelcomeView.vue');
const LoginView = () => import('./auth/LoginView.vue');
const PedidoView = () => import('./pedido/PedidoView.vue');
const PedidoListView = () => import('./pedido/PedidoListView.vue');
const NotFoundView = () => import('./error/NotFoundView.vue');
const SellersView = () => import('./sellers/SellersView.vue');
const ProfileView = () => import('./profile/ProfileView.vue');
const UserInfoView = () => import('./profile/UserInfo/UserInfoView.vue');

export default {
    CatalogoView,
    CatalogoConfigView,
    WelcomeView,
    LoginView,
    PedidoView,
    PedidoListView,
    NotFoundView,
    SellersView,
    ProfileView,
    UserInfoView
};

