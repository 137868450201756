import store from "@/store";

type ObjToSendType = {
    axios: any;
    apiRoute: any;
    searchParams: any;
    url: any;
    showPedidos?: any; // Esta línea hace que 'showPedidos' sea una propiedad opcional
};

export function nextPage(obj: any, event: any) {
    event.preventDefault();
    let objToSend:ObjToSendType = {
        axios: obj.axios,
        apiRoute: obj.apiRoute,
        searchParams: obj.searchParams,
        url: obj.next_page_url,
    };
    if (obj?.showPedidos) {
        objToSend = {
            ...objToSend,
            showPedidos: obj.showPedidos,
        };
    }
    store.dispatch(`${obj.route}`, objToSend);
}

export function prevPage(obj: any, event: any) {
    event.preventDefault();
    let objToSend:ObjToSendType = {
        axios: obj.axios,
        apiRoute: obj.apiRoute,
        searchParams: obj.searchParams,
        url: obj.prev_page_url,
    };
    if (obj?.showPedidos) {
        objToSend = {
            ...objToSend,
            showPedidos: obj.showPedidos,
        };
    }
    store.dispatch(`${obj.route}`, objToSend);
}

export function FirstPage(obj: any, event: any) {
    event.preventDefault();
    if (!obj.isLoadingMore) {
        let objToSend:ObjToSendType = {
            axios: obj.axios,
            apiRoute: obj.apiRoute,
            searchParams: obj.searchParams,
            url: obj.first_page_url,
        };
        if (obj?.showPedidos) {
            objToSend = {
                ...objToSend,
                showPedidos: obj.showPedidos,
            };
        }
        store.dispatch(obj.route, objToSend);
    }
}

export function LastPage(obj: any, event: any) {
    event.preventDefault();
    if (!obj.isLoadingMore) {
        let objToSend:ObjToSendType = {
            axios: obj.axios,
            apiRoute: obj.apiRoute,
            searchParams: obj.searchParams,
            url: obj.last_page_url,
        };
        if (obj?.showPedidos) {
            objToSend = {
                ...objToSend,
                showPedidos: obj.showPedidos,
            };
        }
        store.dispatch(obj.route, objToSend);
    }
}
