import { AuthState } from ".";

const mutations = {
    SET_AUTHENTICATION(state:AuthState, authenticated:boolean) {
        state.authenticated = authenticated
    },
    SET_USER(state:AuthState, user:any) {
        state.user = user
    },
    setConfigUser(state:AuthState, data:any) {
        if (state.user) {

            if (typeof state.user === 'string') {
                // Si state.user es una cadena, intenta analizarla como un objeto JSON
                try {
                    state.user = JSON.parse(state.user);


                    if (!state.user.cliente) {
                        state.user.cliente = {};
                    }

                    state.user.cliente = {
                        ...state.user.cliente,
                        CampoMarcaInv: data.CampoMarcaInv
                    };

                    state.user = JSON.stringify(state.user);
                } catch (error) {
                    // Si no se puede analizar como JSON, manejar el error de manera adecuada
                    console.error('Error al analizar state.user como JSON:', error);
                    // Puedes tomar una acción alternativa aquí, como asignar un valor por defecto a state.user
                }
            }


        }
    },
    resetUser(state:AuthState) {
        state.user = null;
    }
};

export default mutations;