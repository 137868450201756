import { PedidosState, PedidosFilters } from ".";

export default {
    setPedidoData(state:PedidosState, data:any) {
        state.pedidosdata = data;
    },
    setNextPageUrl(state:PedidosState,url:string) {
        state.next_page_url = url;
    },
    setPrevPageUrl(state:PedidosState,url:string) {
        state.prev_page_url = url;
    },
    setFirstPageUrl(state:PedidosState,url:string) {
        state.first_page_url = url;
    },
    setLastPageUrl(state:PedidosState,url:string) {
        state.last_page_url = url;
    },
    setLastPage(state:PedidosState, page:number) {
        state.last_page = page;
    },
    setCurrentPage(state:PedidosState, page:number) {
        state.current_page = page;
    },
    setLoading(state:PedidosState, loading:boolean) {
        state.loading = loading;
    },
    setIsLoadingMore(state:PedidosState, loading:boolean) {
        state.isLoadingMore = loading;
    },
    setSearchParams(state:PedidosState, searchParams:any) {
        state.searchParams = searchParams;
    },
    setErrorMessage(state:PedidosState, message:any) {
        state.errorMessage = message;
    },
    setTotal(state:PedidosState, total:number) {
        state.total = total;
    },
    setShowing(state:PedidosState, showing:any) {
        state.showing = showing;
    },
    setFrom(state:PedidosState, from:number) {
        state.from = from;
    },
    setTo(state:PedidosState, to:number) {
        state.to = to;
    },
    deletePedido(state:PedidosState, id:any) {
        state.pedidosdata = state.pedidosdata.filter((item:any) => item.id !== id);
    },
    SET_SELECTED_FILTERS(state:PedidosState, selectedFilters:PedidosFilters) {
        state.selectedFilters = selectedFilters;
    },
    setButtonLoading(state:PedidosState, loading:boolean) {
        state.buttonLoading = loading
    }
};
