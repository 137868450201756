import Swal from 'sweetalert2';

export default function openInfoCatalogoModal() {
    Swal.fire({
        title: 'Atajos de teclado',
        html: `
            <div class="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-4">
                <div class="shortcut">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Derecha</div>
                    <div>Ir a la siguiente página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Alt</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Arriba</div>
                    <div>Ir al principio de la página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Izquierda</div>
                    <div>Ir a la anterior página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Alt</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Abajo</div>
                    <div>Ir al final de la página</div>
                </div>
                <div class="shortcut">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Enter</div>
                    <div>Enfocar input de carrito</div>
                </div>

                <div class="shortcut md:col-span-2 justify-center font-bold">
                    <h2 class='md:flex items-center'>Atajos con el input del carrito enfocado
                        <span class='shortcut ml-2 mb-0 justify-center'>
                            <div class="key">Ctrl</div>
                            <div class="plus">+</div>
                            <div class="key">Enter</div>
                        </span>
                    </h2>
                </div>

                <div class="shortcut md:col-span-2">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Arriba</div>
                    <div>Pasar al anterior input</div>
                </div>
                <div class="shortcut md:col-span-2">
                    <div class="key">Ctrl</div>
                    <div class="plus">+</div>
                    <div class="key">Flecha Abajo</div>
                    <div>Pasar al siguiente input</div>
                </div>
            </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        focusConfirm: false,
        customClass: {
            popup: 'swal-popup-custom swal-wide',
        },
    });
}
