<template>
    <Line id="chart" :options="chartOptions" :data="chartData" width="300" height="240"/>
</template>
  
<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Tooltip, LineElement, PointElement, CategoryScale, LinearScale)

export default {
    name: 'LineChart',
    components: { Line },
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March'],
                datasets: [{ data: [40, 20, 12] }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true
            }
        }
    }
}
</script>
